.enable_compact_menu() when (@enable-compact-menu = true) {

 .sidebar.compact {
	&, &.navbar-collapse {
		width: @sidebar-compact-width;
	}

	.sidebar-shortcuts {
		max-height: @breadcrumb-height - 1;//40px
	}
	
	.nav-list > li {
		&:before {
			height: auto;
			bottom: -1px;
		}
	}
	.nav-list > li > a {
		height: auto;
		line-height: 16px;
		padding: 2px 0 8px;
		text-align: center;
		
		> .menu-icon {
			display: block;
			margin : 0;
			vertical-align: inherit;
			line-height: 32px;
			height: 32px;
			
			font-size: @nav-item-icon-size + 2;
		}
		> .arrow {
			display: none;
		}
	}
	

	.nav-list a {
		.badge , .label {
			right: 12px;
		}
	}
	.nav-list > li.active > .submenu , .nav-list > li.open > .submenu {
		display: none;
	}

	+ .main-content {
		margin-left: @sidebar-compact-width;
	}
 }


 //reset .compact styles for small screens with .navbar-collapse
 .enable_collapsible_responsive_compact_menu() when (@enable-collapsible-responsive-menu = true) {
  @media only screen and (max-width: @grid-float-breakpoint-max) {
	.sidebar.compact + .main-content {
		margin-left: 0;
	}
	.sidebar.navbar-collapse {
		&.compact {
			.nav-list > li > a {
				text-align: left;
				height: @nav-item-height;
				line-height: (@nav-item-height - 2);
				
				padding: 0 16px 0 7px;
				
				> .menu-icon {
					display: inline-block;
					vertical-align: sub;
					height: auto;
					line-height: inherit;
					margin: 0 2px 0 0;
					font-size: @nav-item-icon-size;
				}
				> .menu-text {
					display: inline;
				}
				> .arrow {
					display: inline;
				}
			}

			+ .main-content {
				margin-left: 0;
			}
		}
	}
  }//@media
 }
 .enable_collapsible_responsive_compact_menu();


}

.enable_compact_menu();