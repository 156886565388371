@import (reference) "./ace.less";






.enable_plugin_select2() when(@enable-plugin-select2 = true) {


.select2-container .select2-choice {
	.border-radius(0);
	 height: 30px;
}
.select2-container.select2-drop-above .select2-choice {
	.border-radius(0);
}
.select2-container[class*="input-"] {
	max-width: none;
}
.select2-container.input-mini {
	min-width: 100px;
}

.select2-container .select2-choice abbr , .select2-search-choice-close {
    background: none;
	&:before {
		font-family:FontAwesome;
		font-size: 12px;
		display: inline;
		content: "\f00d";
		color: #888;
		position: relative;
		top: -1px;
	}
	&:hover:before {
		color:#555;
	}
}
.select2-container .select2-choice abbr:before {
	top:-7px;
}
.select2-search-choice-close:hover {
	text-decoration:none !important;
}


.select2-drop {
	.border-radius(0);
	border:1px solid #4492C9;
	border-width:0 1px 3px;
}
.select2-drop.select2-drop-above {
	.border-radius(0);
}
.select2-container .select2-choice {
	background:#FAFAFA none;
}


.select2-container-active .select2-choice,
.select2-container-active .select2-choices,
.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices,
.select2-container-multi.select2-container-active .select2-choices
{
	border-color:#4492C9;
}

.select2-results .select2-highlighted {
    background: #316AC5;
}
.select2-container .select2-choice .select2-arrow {
	.border-radius(0);
	background:transparent none;
	border:none;
}
.select2-container .select2-choice .select2-arrow b {
    background: none;
	&:before {
		font-family: FontAwesome;
		font-size: 12px;
		display: inline;
		content: "\f0d7";
		color: #888;
		position: relative;
		left: 5px;
	}
}
.select2-dropdown-open  .select2-choice .select2-arrow b:before {
	content:"\f0d8";
}

.select2-search {
	.select2-input {
		background: #fff none;
		margin-top:4px;
	}
	&:after {
		font-family: FontAwesome;
		font-size: 14px;
		display: inline;
		content: "\f002";
		color: #777;
		position: relative;
		top: 0;
		left: -20px;
		z-index: 0;
	}
}
.select2-container-multi .select2-choices .select2-search-field input {
	border: none !important;
	.box-shadow(none) !important;
	background: none !important;
}


.select2-container-active .select2-choice,
.select2-container-active .select2-choices,
.select2-container-multi.select2-container-active .select2-choices,
.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices
{
    .box-shadow(none);
}

.select2-search input.select2-active {
	background-color:#FFF;
    position: relative;
    z-index: 1;//so it will be on top of search icon
}


@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)  {
  .select2-search input, .select2-search-choice-close, .select2-container .select2-choice abbr, .select2-container .select2-choice div b {
      background-image: none !important;
      background-size: auto !important;
  }
  .select2-search input {
      background-position: auto !important;
  }
}


.select2-container-active.select2-dropdown-open {
	.select2-choice {
		#gradient > .vertical(#EEEEEE , #FFFFFF);
	}
}
.select2-container-active.select2-drop-above {
	.select2-choice {
		#gradient > .vertical(#FFFFFF , #EEEEEE);
	}
}


.select2-choice , .select2-choices {
	.form-group.has-error & {
		border-color:@error-state-border !important;
	}
	.form-group.has-info & {
		border-color:@info-state-border !important;
	}
	.form-group.has-warning & {
		border-color:@warning-state-border !important;
	}
	.form-group.has-success & {
		border-color:@success-state-border !important;
	}
}



@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)  {
  .select2-search input, .select2-search-choice-close, .select2-container .select2-choice abbr, .select2-container .select2-choice .select2-arrow b {
      background-image: none !important;
      background-repeat: no-repeat !important;
      background-size: auto !important;
  }
  .select2-search input {
      background-position: auto !important;
  }
}




}

.enable_plugin_select2();
