@import (reference) "./ace.less";






@dropdown-shadow:~"0 2px 4px rgba(0, 0, 0, 0.2)";


// dropdown menus
.icon-white,
.nav-pills > .active > a > .@{icon},
.nav-list > .active > a > .@{icon},
.navbar-inverse .nav > .active > a > .@{icon},
.dropdown-menu > li > a:hover > .@{icon},
.dropdown-menu > li > a:focus > .@{icon},
.dropdown-menu > .active > a > .@{icon},
.dropdown-submenu:hover > a > .@{icon},
.dropdown-submenu:focus > a > .@{icon} {
 background-image: none;
}



.dropdown-menu {
 .border-radius(3) !important;
 .box-shadow(@dropdown-shadow);
 > li > a {
	font-size: @font-size-dropdown-menu;
	padding-left: 11px;
	padding-right: 11px;
	margin-bottom: 1px;
	margin-top: 1px;
 }

 &.dropdown-only-icon {
	min-width: 50px;
	> li {
		//float:left;
		margin:0 4px;
		> a {
			.@{icon} {
				width: 18px;
				//font-size: 16px;
				display: inline-block;
				text-align: center;
			}
			.icon-2x {
				width: 36px;
				//font-size:22px;
			}
		}// > a
	}// > li
 }//&.dropdown-only-icon

}//.dropdown-menu



.enable_dropdown_colors() when(@enable-dropdown-colors = true) {
//dropdown
.dropdown-color(@bgcolor:~"menu";@txtcolor:#FFFFFF) {
  @dropdown-class:~`"dropdown-@{bgcolor}"`;
  @dropdown-bg:~`"dropdown-@{bgcolor}"`;
  @dropdown-cl:@@dropdown-bg;


  .@{dropdown-class} {
	> li > a:hover,
	> li > a:focus,
	> li > a:active,
	> li.active > a,
	> li.active > a:hover,
	.dropdown-submenu:hover > a
	//,.nav-tabs & li > a:focus
	{
		background: @dropdown-cl;
		color: @txtcolor;
	}
  }
}

.dropdown-color();
.dropdown-color(~"default");
.dropdown-color(~"info");
.dropdown-color(~"primary");
.dropdown-color(~"success");
.dropdown-color(~"warning");
.dropdown-color(~"danger");
.dropdown-color(~"inverse");
.dropdown-color(~"purple");
.dropdown-color(~"pink");
.dropdown-color(~"grey");
.dropdown-color(~"light" ; #333333);
.dropdown-color(~"lighter" ; #444444);
.dropdown-color(~"lightest" ; #444444);
.dropdown-color(~"yellow" ; #444444);
.dropdown-color(~"yellow2" ; #444444);
.dropdown-color(~"light-blue" ; #445566);


.dropdown-light , .dropdown-lighter , .dropdown-lightest {
  .dropdown-submenu:hover > a:after {
	border-left-color:#444;
  }
}

}
.enable_dropdown_colors();



//closer to the toggle button
.dropdown-menu {
 &.dropdown-close {
	top:92%;
	left:-5px;
	&.dropdown-menu-right {
		left:auto;
		right:-5px;
	}
 }
 &.dropdown-closer {
	top:80%;
	left:-10px;
	&.dropdown-menu-right {
		right:-10px;
		left:auto;
	}
 }
}

.dropup , .navbar-fixed-bottom .dropdown {
 > .dropdown-menu {
	top: auto !important;
	bottom: 100%;
	
	&.dropdown-close {
		bottom: 92%;
	}
	&.dropdown-closer {
		bottom: 80%;
	}
 }
}




.dropdown-submenu > .dropdown-menu {
 .border-radius(0);
}
.dropdown-submenu > a:after {
 margin-right:-5px;
}



.dropdown-50 {
	min-width: 50px;
}
.dropdown-75 {
	min-width: 75px;
}
.dropdown-100 {
	min-width: 100px;
}
.dropdown-125 {
	min-width: 125px;
}
.dropdown-150 {
	min-width: 150px;
}

 



.dropdown-hover {
	position: relative;
	&:before {
		//for better hoverability
		display: block;
		content: "";
		
		position: absolute;
		//z-index: auto;
		top: -2px;
		bottom: -2px;
		left: -2px;
		right: -2px;
		
		background-color: transparent;
	}
}
.dropdown-hover:hover > .dropdown-menu {
	display: block;
}

.dropdown-menu > .dropdown-hover > .dropdown-menu {
	top: -5px;
	left: 99%;
	right: auto;
}
.dropdown-menu > .dropdown-hover > .dropdown-menu.dropdown-menu-right {
	left: auto;
	right: 99%;
}
.dropup .dropdown-menu > .dropdown-hover > .dropdown-menu,
.dropdown-menu > .dropdown-hover.dropup > .dropdown-menu
 {
	top: auto;
	bottom: -5px;
}






//carets
.dropdown-menu.dropdown-caret:before {
    border-bottom: 7px solid rgba(0, 0, 0, 0.2);
	-moz-border-bottom-colors: rgba(0, 0, 0, 0.2);
	
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: "";
    display: inline-block;
    left: 9px;
    position: absolute;
    top: -7px;
}
.dropdown-menu.dropdown-caret:after {
    border-bottom: 6px solid #FFF;
	-moz-border-bottom-colors: #FFF;
	
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: "";
    display: inline-block;
    left: 10px;
    position: absolute;
    top: -6px;
}

.dropdown-menu.dropdown-menu-right.dropdown-caret:before {
    left: auto;
	right:9px;
}
.dropdown-menu.dropdown-menu-right.dropdown-caret:after {
    left: auto;
	right:10px;
}


//drop up
.dropup , .navbar-fixed-bottom .dropdown {
	> .dropdown-menu.dropdown-caret:before {
		border-bottom-width: 0;
		border-top: 7px solid rgba(0, 0, 0, 0.2);
		-moz-border-top-colors: rgba(0, 0, 0, 0.2);
		
		bottom: -7px;
		top: auto;
	}
	> .dropdown-menu.dropdown-caret:after {
		border-bottom-width: 0;
		border-top: 6px solid #FFF;
		-moz-border-top-colors: #FFF;

		bottom: -6px;
		top: auto;
	}
}





//colorpicker dropdown
@import "dropdown/colorpicker.less";
@import "dropdown/navbar-dropdown.less";
@import "dropdown/navbar-tabbed-dropdown.less";
