.configurator {
    /////////////////////////////
	& > .group {
		border:1px solid #e8e8e8;
		background:#EEE;
		border-left-width:4px;
		padding:15px;
		margin-bottom:30px;
		& > .row {
			& > .header {
		    border-bottom: 1px solid #EEE;
		    margin: 0;
     		padding-bottom: 11px;
				& > .pull-right {
					display: inline-block;
				}
				& > .left {
					display: inline-block;
				}
			}
		  & > .draggable {
		  	min-height: 300px;
		  }
		  & > .add_new_button {
		  }
		}
	}

	& > .form_field {
		border: 1px solid #e8e8e8;
    background: #FFF;
    margin: 0;
		margin-bottom:5px;
    padding: 15px 10px 20px 10px;
    cursor: move;
		& > .row {
			& > .header {
				border-bottom: 1px solid #EEE;
				margin:0;
		    margin-bottom: 15px;
		    padding-bottom: 15px;
				& > .left {
					display: inline-block;
				}
			}
			& > .configuration {
				& > .form_input {
					& > label {
					}
					& > input {
					}
				}
			}
		}
	}

}

.preview {
	& > .group {

	}

	& > .checkbox {

	}

	& > .radio {

	}

	& > .textfield {

	}

	& > .dropdown {

	}
}

.dropdiv {
    margin-bottom: 5px;
}

.form_input_container {
	margin-bottom: 17px;
}

.bottomspace {
	margin-bottom: 20px;
}

.sortable-ghost {
	border: 1px dashed #0088F8;
  background: rgba(0, 136, 249, 0.09);
  color: #0088f9;
  display: flex;
}

.panel-group .panel-heading {
	border-bottom: 1px solid #e8e8e8;
	padding-bottom: 25px;
	margin-top: -5px;

}

.trash {
	display: inline-block;
	margin-top: 5px;
}