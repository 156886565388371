@import (reference) "../bootstrap/variables.less";
@import (reference) "../bootstrap/mixins.less";

@import (reference) "../ace-features.less";
@import (reference) "../variables.less";//there are also some variables on top of some other less files
@import (reference) "../mixins.less";

//FF_fix
@media only screen and (min--moz-device-pixel-ratio:0) {
 .navbar.navbar-fixed-top {
	border-radius: 0.05em;
	& , > .navbar-container , .ace-nav > li .dropdown-menu {
		-moz-backface-visibility: hidden;
	}
	// ".ace-nav > li .dropdown-menu" becomes fuzzy if it has scrollbars
 }
 .sidebar.sidebar-fixed {
	-moz-backface-visibility: hidden;
 }
}
