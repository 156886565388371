@import (reference) "../ace.less";

.enable_plugin_tag_input() when(@enable-plugin-tag-input = true) {

.tags {
  display: inline-block;
  padding: 4px 6px;
  color: @ace-grey;
  vertical-align: middle;
  //.border-radius(@inputBorderRadius);
  background-color: #FFF;
  border: 1px solid @input-border;

  //.box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
 
  //.transition(~"border linear .2s, box-shadow linear .2s");
  width: 206px;
  
  &:hover {
	border-color:@input-hover-border;
  }
  
  &-hover , &-hover:hover {
    border-color: @input-border-focus;
    outline: 0;
    //outline: thin dotted \9; /* IE6-9 */
  }

  &[class*="span"] {
    float: none;
    margin-left: 0;
  }
  
  input[type="text"],
  input[type="text"]:focus {
    border: none;
    display: inline;
    outline: 0;
    margin: 0;
    padding: 0;
    line-height: 14px;
    .box-shadow(none);
    width: 100%;

    &.form-control.has-error {
      border-color: #daa7af;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
      color: #a94442;
      background: #f2dede;
    }
  }
}

.tags {
 .tag {
  display: inline-block;
  position:relative;
  
  font-size: @baseFontSize;
  font-weight: normal;

  vertical-align: baseline;
  white-space: nowrap;
  
  background-color:@tag-bg;
  
  padding: 4px 22px 5px 9px;
  border-radius: 2px;
  margin-bottom: 3px;
  margin-right: 3px;
  .transition(~"all 0.2s");

  button {
    color: initiaL;
  }
  
  &:empty {
    display: none;
  }
  
  &:hover {
	//background-color: #B2CADD;
  }

/**
  &:nth-child(5n+1) {
	background-color:#48A2E0;
  }
  &:nth-child(5n+2) {
	background-color:#34C896;
  }
  &:nth-child(5n+3) {
	background-color:#B57BB3;
  }
  &:nth-child(5n+4) {
	background-color:#CC7DA8;
  }
  &:nth-child(5n+5) {
	background-color:#666;
  }
*/
  
  
  // Important (red)
  &-important   { background-color: @btn-danger; }
  // Warnings (orange)
  &-warning     { background-color: @btn-warning; }
  // Success (green)
  &-success     { background-color: @btn-success; }
  // Info (turquoise)
  &-info        { background-color: @btn-info; }
  // Inverse (black)
  &-inverse     { background-color: @btn-inverse; }

  .close {
    font-size: 15px;
    line-height: 20px;

	.opacity(1);
	color: #FFF;
	text-shadow: none;
	
	float: none;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	width: 18px;
	text-align: center;
	
	&:hover {
		background-color: rgba(0,0,0,0.2);
	}
  }
 }
}





}
.enable_plugin_tag_input();
