.multi-select {
  display: flex;
  flex-direction: row;
  justify-content: space-around;


  .multi-select-wrapper {
    flex-grow: 1;
    flex-shrink: 1;

    width: 50%;

    display: flex;
    flex-direction: column;

    & > h1 {
      font-size: 15px;
    }
  }

  .multi-select-selected, .multi-select-unselected {
    flex-grow: 1;
    flex-shrink: 1;

    height: 200px;
    overflow: auto;

    border: 1px solid #e8e8e8;

    .multi-select-item {

      &.multi-select-item--selected {
        background-color: #1984E8;
        color: white;
      }

      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    }
  }

  .multi-select-swap {
    flex-grow: 0;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    font-size: 20px;
    cursor: pointer;

    .multi-select-swap-icon {
    }
  }

  & > div {
    margin: 1em;
  }
}