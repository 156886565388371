@import (reference) "./ace.less";






.enable_footer() when (@enable-footer = true) {
.footer {
  @footer-offset: @grid-gutter-width / 2;

  padding-top: 64px;
  height: 0;
  width: 0;
  
  .footer-inner {
	 text-align: center;

	 position: absolute;
	 z-index: auto;
	 left: @sidebar-width;
	 right: 0;
	 bottom: 0;

	 .sidebar.compact ~ & {
		left: @sidebar-compact-width;
	 }
	 .sidebar.menu-min ~ & {
		left: @sidebar-min-width;
	 }
	 @media (min-width: @grid-float-breakpoint) {
	   .sidebar.h-sidebar ~ & {
		  left: 0;
	   }
	 }
	 
	 @media (max-width: @grid-float-breakpoint-max) {
		& , .sidebar.compact ~ & , .sidebar.menu-min ~ & {
			left: 0;
		}
		.sidebar.responsive-min ~ & {
			left: @sidebar-min-width;
		}
	 }


	 .enable_container_footer() when(@enable-container = true) {
		 .container.main-container & {
			 @media (min-width: @screen-sm-min) and (max-width: @grid-float-breakpoint-max) {
				left: auto !important;//for RTL
				right: auto !important;//for RTL
			
				margin-left: auto;
				width: @container-sm ;
			 }
			 @media (min-width: @screen-md-min) {
				left: auto !important;//for RTL
				right: auto !important;//for RTL
			
				margin-left: @sidebar-width;
				width: @container-md - @sidebar-width;
			 }
			 @media (min-width: @screen-lg-min) {
				margin-left: @sidebar-width;
				width: @container-lg - @sidebar-width;
			 }
		 }
		 
		 .enable_compact_menu_footer_container() when (@enable-compact-menu = true) {
		  .container.main-container .sidebar.compact ~ & {
			 @media (min-width: @screen-md-min) {
				margin-left: @sidebar-compact-width;
				width: @container-md - @sidebar-compact-width;
			 }
			 @media (min-width: @screen-lg-min) {
				margin-left: @sidebar-compact-width;
				width: @container-lg - @sidebar-compact-width;
			 }
		  }
		 }
		 .enable_compact_menu_footer_container();
		 
		 
		 .enable_collapse_menu_footer_container() when (@enable-sidebar-collapse = true) {
		  .container.main-container .sidebar.menu-min ~ & {
			 @media (min-width: @screen-md-min) {
				margin-left: @sidebar-min-width;
				width: @container-md - @sidebar-min-width;
			 }
			 @media (min-width: @screen-lg-min) {
				margin-left: @sidebar-min-width;
				width: @container-lg - @sidebar-min-width;
			 }
		  }
		 }
		 .enable_collapse_menu_footer_container();
		 
		 
		 .enable_horizontal_menu_footer_container() when (@enable-horizontal-menu = true) {
		  .container.main-container .sidebar.h-sidebar ~ & {
			 @media (min-width: @grid-float-breakpoint) {
				margin-left: 0;
				width: @container-md;
			 }
			 @media (min-width: @screen-md-min) {
				margin-left: 0;
				width: @container-md;
			 }
			 @media (min-width: @screen-lg-min) {
				margin-left: 0;
				width: @container-lg;
			 }
		  }
		 }
		 .enable_horizontal_menu_footer_container();

		 
		 .enable_responsive_min_menu_footer_container() when (@enable-minimized-responsive-menu = true) {
		  .container.main-container .sidebar.responsive-min ~ & {
		    @media (min-width: @screen-sm-min) and (max-width: @grid-float-breakpoint-max) {
				margin-left: @sidebar-min-width;
				width: @container-sm - @sidebar-min-width;
			}
		  }
		 }
		 .enable_responsive_min_menu_footer_container();
		 
	}
	.enable_container_footer();
	
	

	.footer-content {
		 position: absolute;

		 left: @footer-offset;
		 right: @footer-offset;
		 bottom: 4px;
		 
		 padding: 8px;
		 line-height: 36px;	
		 
		 border-top: 3px double #E5E5E5;
	}
			 
  }//.footer-inner
  

  &.footer-fixed .footer-inner {
	position: fixed;
	z-index: 999;
	bottom: 0;
	
	.footer-content {
		left: 2px;
		right: 2px;
		bottom: 2px;
		border: 1px solid #E8E8E8;
		background-color: #F7F7F7;
	}
  }
  
  &.footer-fixed + .btn-scroll-up {
	z-index: 999;
  }

  
}//.footer

}

.enable_footer();
