@import (reference) "./ace.less";

.enable_plugin_mindmup_wysiwyg() when(@enable-plugin-mindmup-wysiwyg = true) {

.wysiwyg-editor {
	max-height: 250px;
	height: 250px;
	background-color: #F7F8FA;
	border-collapse: separate;
	border: 1px solid #BBC0CA; 
	padding: 4px; 
	box-sizing: content-box; 
	overflow-y: scroll;
	overflow-x:hidden;
	outline: none;
	
	&:focus {
		background-color:#FFF;
	}
}


.wysiwyg-toolbar {
	line-height: 33px;
	margin: 0 !important;
	position: relative;
	
	.dropdown-menu {
		text-align: left;
	}
	
	.btn-group {
		float: none !important;//to override .RTL's
		font-size: 0;
	}

	.btn-group > .btn {
		//sometimes we need to put .btn inside an .inline.position-relative for dropdowns to show up in correct position
		float: none;

		padding-left: 0;
		padding-right: 0;
		text-align: center;
		margin-left: 1px;
		
		> .@{icon}:first-child {
			font-size: @font-size-wysiwyg-button-icon;
			width: 25px;
			max-width: 25px;
			display: inline-block;
			border-width: 1px !important;
		}
		&.dropdown-toggle > .@{icon}:last-child {
			margin-right: 4px;
		}

		/**
		&.active:after {
			border-color: transparent;
			border-style: solid;
			border-top-color: inherit;
			border-width: 6px 14px;
			bottom: -13px;
			left: 0;
			right: 0;
		}
		*/
	}
}


.wysiwyg-style1 , .wysiwyg-style2 {
	.btn-group > .btn , .btn-group > .inline > .btn {
		margin: 0 !important;
		background: #FFF !important;
		border-width: 0 !important;
		color: #ADB3BE !important;
		text-shadow: none !important;
		
		&.active {
			color: #5B80CE !important;
			&:after {
				display: none;
			}
		}
	}
	
	.btn-group {
		position:relative;
		&:after {
			display: block;
			content: "";
			position: absolute;
			left: -2px; 
			top: 6px;
			bottom:6px;
			width: 0;
			max-width: 0;
			border-left:1px solid #E1E6EA;
		}
		&:first-child:after {
			display:none;
		}
	}
}


.wysiwyg-style2 {
	background-color:#E5E5E5;
	
	& + .wysiwyg-editor {
		border-color:#DDD;
		background-color:#FFF;
		border-top:none;
	}
	
	.btn-group > .btn , .btn-group > .inline > .btn  {
		margin:0 1px 0 0 !important;
		background:#FFF !important;
		border:none !important;
		color:#8D939E !important;
		text-shadow:none !important;
		
		&.active  {
			color:#FFF !important;
			background:#6AAEDF !important;
		}
	}
	.btn-group:after {
		display:none;
	}
	
}




.wysiwyg-toolbar {
	.btn-colorpicker {
		width: 24px;
		height: 24px;
		position: relative;
		//border-radius: 12px;

		background: #87B87F; /* Old browsers */
		background: -moz-linear-gradient(top, #CF3E73 10%, #FFFFFF 20%, #2283C5 30%, #FFFFFF 40%, #87B87F 50%, #FFFFFF 60%, #FFB752 70%, #FFFFFF 80%, #D15B47 90%, #FFFFFF 100%); /*  FF3.6+ */
		background: -webkit-gradient(linear, left top, left bottom, color-stop(10%,#CF3E73), color-stop(20%,#FFFFFF), color-stop(30%,#2283C5), color-stop(40%,#FFFFFF), color-stop(50%,#87B87F), color-stop(60%,#FFFFFF), color-stop(70%,#FFB752), color-stop(80%,#FFFFFF), color-stop(90%,#D15B47), color-stop(100%,#FFFFFF)); /* Chrome,Safari4+ */
		background: -webkit-linear-gradient(top, #CF3E73 10%, #FFFFFF 20%, #2283C5 30%, #FFFFFF 40%, #87B87F 50%, #FFFFFF 60%, #FFB752 70%, #FFFFFF 80%, #D15B47 90%, #FFFFFF 100%); /* Chrome10+,Safari5.1+ */
		background: -o-linear-gradient(top, #CF3E73 10%, #FFFFFF 20%, #2283C5 30%, #FFFFFF 40%, #87B87F 50%, #FFFFFF 60%, #FFB752 70%, #FFFFFF 80%, #D15B47 90%, #FFFFFF 100%); /* Opera11.10+ */
		background: -ms-linear-gradient(top, #CF3E73 10%, #FFFFFF 20%, #2283C5 30%, #FFFFFF 40%, #87B87F 50%, #FFFFFF 60%, #FFB752 70%, #FFFFFF 80%, #D15B47 90%, #FFFFFF 100%); /* IE10+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#CF3E73', endColorstr='#FFB752',GradientType=0 ); /* IE6-9 */
		background: linear-gradient(top, #CF3E73 10%, #FFFFFF 20%, #2283C5 30%, #FFFFFF 40%, #87B87F 50%, #FFFFFF 60%, #FFB752 70%, #FFFFFF 80%, #D15B47 90%, #FFFFFF 100%); /* W3C */
	}
	.dropdown-colorpicker > .dropdown-menu {
		top: auto;
	}

	input[type=file]  {
		position: fixed;
		z-index: -10;
		opacity: 0;
		//inside modals the z-index:-10 does not work as expected
		max-width: 0;
		max-height: 0;
		display: block;
	}
	.wysiwyg-choose-file {
		display:inline-block;
		width:auto;
		margin:4px auto 0;
		padding-left:5px;
		padding-right:5px;
	}
	.dropdown-menu {
		input[type=text] {
			margin-left:8px;
			margin-bottom:0;
			&.form-control {
				min-width: 150px;
			}
		}
		.btn {
			margin-right: 8px;
			margin-left: 8px;
		}
	}
}
.wysiwyg-style1 .btn-colorpicker {
	width: 20px;
	height: 20px;
	margin-left: 4px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
 /* for adding image resize functionality in chrome and safari */
 .wysiwyg-editor {
	img {
		display:inline !important;
	}
	.ui-wrapper {
	  border:1px dotted #D00;
	  overflow:visible !important;/* because it's image only, so it's ok */
	  display:inline-block !important;
	  vertical-align:middle;
	  &:after {
		  content:"";
		  display:block;
		  position:absolute;
		  right:-3px; bottom:-3px;
		  width:7px; height:7px;
		  border:1px solid #D00;
		  background-color:#FFF;
		  z-index:1;
	   }
	}
 }
}




/* inside widget */
.widget-header .wysiwyg-toolbar {
	background-color:transparent;
	.btn-group > .btn , .btn-group > .inline > .btn {
		border-color:transparent;
		background:rgba(255,255,255,0.25) !important;
		color:#FFF !important;
		
		min-width: 32px;

		border-width: 1px !important;
		border-radius: 4px !important;

		padding: 2px 1px 4px;
		
		&.active {
			background:rgba(0,0,0,0.25) !important;
		}
	}
}

.widget-body .wysiwyg-editor {
	border-width: 0;
}

.wysiwyg-speech-input {
	width: 20px !important;
	color: transparent !important;
	background: transparent none !important;
	border-width: 0 !important;

	.transform(~"scale(2.0, 2.0)");
	.box-shadow(none) !important;
	
	position: absolute;
	right: 0;
	top: -10px;
	
	cursor: pointer;
	&:focus {
		.box-shadow(none) !important;
	}
}

}
.enable_plugin_mindmup_wysiwyg();



//Markdown Editor
.enable_markdown_editor() when(@enable-markdown-editor = true) {
.widget-body .md-header {
	margin-top:-30px;
	margin-left:9px;

	.btn {
		border-color: transparent;
		background: rgba(255,255,255,0.25) !important;
		color: #FFF !important;
		
		text-align: center;
		min-width: 32px;

		border-width: 1px !important;
		border-radius: 4px !important;

		padding: 2px 4px 4px;
		
		> .@{icon} {
			font-size: 14px;
			width: 25px;
			max-width: 25px;
			display: inline-block;
		}
	}

	.btn-inverse {
		background: rgba(0,0,0,0.25) !important;
		padding-right: 5px;
		margin-left: 4px;
	}
}

.widget-body {
	.md-preview {
		padding:8px;
		min-height:200px;
	}
	
	.md-input {
		border:none !important;
		box-shadow:none !important;
		display:block;
		margin-bottom:0;
		background-color:rgba(48, 126, 204, 0.07);
		padding:8px;
		width: 100%;
		
		&:focus {
			background-color:#FFF;
			box-shadow:none !important;
		}
	}
}


}
.enable_markdown_editor();
