@import (reference) "./ace.less";






//labels & badges
.label {
 border-radius: 0;
 text-shadow: none;
 font-weight: normal;

 display: inline-block;

 background-color: @label-default !important;

 &[class*="col-"][class*="arrow"] {
	min-height:0;
 }
}


@media screen and (-webkit-min-device-pixel-ratio:0) {
	//default display:none causes problems with Android Webview
	::safari-only,.label:empty {
		display: inline-block;
	}
	::safari-only,.badge:empty {
		display: inline-block;
	}
}




.badge {
 text-shadow: none;
 font-size: @font-size-badge;
 padding-top: 3px;
 padding-bottom: 2px;
 font-weight: normal;
 line-height: 15px;
 background-color: @theme-color-main !important;

 &.no-radius { border-radius: 0; }
 &.radius-1 { border-radius: 1px; }
 &.radius-2 { border-radius: 2px; }
 &.radius-3 { border-radius: 3px; }
 &.radius-4 { border-radius: 4px; }
 &.radius-5 { border-radius: 5px; }
 &.radius-6 { border-radius: 6px; }
}
.label-transparent, .badge-transparent {
	background-color:transparent !important;
}



.enable_label_colors() when(@enable-label-colors = true) {

//labels
.label-color(@color) {
  @label-class:~`"label-@{color}"`;
  @badge-class:~`"badge-@{color}"`;
  @label-color:@@label-class;

 .@{label-class}, .@{badge-class} {
	background-color:@label-color !important;
 }
}


.label-color(~"grey");
.label-color(~"info");
.label-color(~"primary");
.label-color(~"success");
.label-color(~"danger");
.label-color(~"important");
.label-color(~"inverse");
.label-color(~"warning");
.label-color(~"pink");
.label-color(~"purple");
.label-color(~"yellow");
.label-color(~"light");


.badge-yellow, .label-yellow {
	color: #996633 !important;
	border-color: @label-yellow;
}
.badge-light, .label-light {
	color: #888 !important;
}



.label.arrowed , .label.arrowed-in {
 position:relative;
 z-index: 1;
 &:before {
	display: inline-block;
	content: "";
	position: absolute;
	top: 0;
	z-index: -1;

	border: 1px solid transparent;

	border-right-color: @label-default;
	-moz-border-right-colors: @label-default;
 }
}
.label.arrowed-in:before {
	border-color: @label-default;
	border-left-color: transparent !important;
	-moz-border-left-colors: none !important;
}


.label.arrowed-right , .label.arrowed-in-right {
 position: relative;
 z-index: 1;
 &:after {
	display: inline-block;
	content: "";
	position: absolute;
	top: 0;
	z-index: -1;

	border: 1px solid transparent;

	border-left-color: @label-default;
	-moz-border-left-colors: @label-default;
 }
}
.label.arrowed-in-right:after {
	border-color: @label-default;
	border-right-color: transparent !important;
	-moz-border-right-colors: none !important;
}



.label-arrow(@color) {
   @label-class:~`"label-@{color}"`;
   @label-color:@@label-class;

  .@{label-class}{
	&.arrowed:before {
		border-right-color: @label-color;
		-moz-border-right-colors: @label-color;
	}
	&.arrowed-in:before {
		border-color: @label-color;
		-moz-border-right-colors: @label-color;
	}

	&.arrowed-right:after {
		border-left-color: @label-color;
		-moz-border-left-colors: @label-color;
	}
	&.arrowed-in-right:after {
		border-color: @label-color;
		-moz-border-left-colors: @label-color;
	}
  }
}
.label-arrow(~"info");
.label-arrow(~"primary");
.label-arrow(~"success");
.label-arrow(~"warning");
.label-arrow(~"important");
.label-arrow(~"danger");
.label-arrow(~"inverse");
.label-arrow(~"pink");
.label-arrow(~"purple");
.label-arrow(~"yellow");
.label-arrow(~"light");
.label-arrow(~"grey");

}
.enable_label_colors();



.label {
 .label-size(12px, 1.15, 20px, 10px, 5px);
}
.label-lg {
  padding:0.3em 0.6em 0.4em;
 .label-size(13px, 1.1, 24px, 12px, 6px);
}
.label-xlg {
  padding:0.3em 0.7em 0.4em;
 .label-size(14px, 1.3, 28px, 14px, 7px);
}
.label-sm {
  padding:0.2em 0.4em 0.3em;
 .label-size(11px, 1, 18px, 9px, 4px);
}
.label > span , .label > .@{icon} {
 line-height: 1;
 vertical-align: bottom;
}


.label-size(@font-size, @line-height, @height, @border-height, @border-width) {
  font-size: @font-size;
  line-height: @line-height;
  height: @height;

  &.arrowed {
	margin-left: @border-width;
	&:before {
		left: -(@border-width * 2);
		border-width: @border-height @border-width;
	}
  }
  &.arrowed-in {
	margin-left: (@border-width);
	&:before {
		left: -@border-width;
		border-width: @border-height @border-width;
	}
  }

  &.arrowed-right {
	margin-right: @border-width;
	&:after {
		right: -(@border-width * 2);
		border-width: @border-height @border-width;
	}
  }
  &.arrowed-in-right {
	margin-right: (@border-width);
	&:after {
		right: -@border-width;
		border-width: @border-height @border-width;
	}
  }
}



