@import (reference) "./ace.less";

//the following column widths need not be floated, as they are full width (100%)
//but in our case it's needed, because sidebar is floated left(not position:absolute) and
//the .row elements containing .col-*-12 have .clearfix behavior,
//which doesn't allow any floating elements on left or right, thus causing problems with our sidebar
.page-content > .row > {
 .col-xs-12 , .col-sm-12 , .col-md-12, .col-lg-12 {
   float: left; 
   max-width: 100%;//if not, .col-xs-12 > .row > .col-sm-12 will have problems
 }
}

.col-xs-reset { width: auto; padding-left: 0; padding-right: 0; float: none !important; }

@media (min-width: @screen-sm-min) {
 .col-sm-reset { width: auto; padding-left: 0; padding-right: 0; float: none !important; }
}
@media (min-width: @screen-md-min) {
 .col-md-reset { width: auto; padding-left: 0; padding-right: 0; float: none !important; }
}
@media (min-width: @screen-lg-min) {
 .col-lg-reset { width: auto; padding-left: 0; padding-right: 0; float: none !important; }
}


.jqstooltip , .legendColorBox div {
 .box-sizing(content-box);
}
.legendLabel {
 .box-sizing(content-box);
 height: 22px; 
 padding-left: 2px;
 font-size: @font-flot-chart-label;
}
