.seat-edit-selected-seat, .seat-create-selected-seat {
	box-shadow: 0px 0px 1px #000000, 
		0px 0px 2px 2px #FFFFFF;
}

#add-new-seat-form * {
	z-index: 999;
}

.multi-seat-edit {
	display: none;
}