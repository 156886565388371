@import "./ace";
@import "./ace-features";

// Import font-awesome to use it as a mixin.
@import (reference) "./custom_css/font-awesome";
@import (reference) "./variables.less";

code, pre, .monospace {
    @fweight: @fweight-mono;

    font-family: "Fira Code VF", monospace !important;
    font-optical-sizing: auto;
    font-weight: @fweight;
    font-style: normal;
    font-variation-settings: "wght" @fweight;
    font-variant-ligatures: discretionary-ligatures;
}


.login {
  .full-height-elem() {
    height: calc(~"100vh - 45px");
    display: flex;
    flex-direction: column;
  }

  .fill-available() {
      flex-shrink: 1;
      flex-grow: 1;
  }

  .main-content {
    .full-height-elem();

    > .page-container {

      .full-height-elem();
      .fill-available();

      > .login-container {
        .fill-available();
        margin: 0;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;

        .login-container-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          > div {
            width: 350px;

            .widget-main {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }

}
.fa-mixin(@name) {
  .fa();
  &:before {
    @var-name: "fa-var-@{name}";
    content: @@var-name;
  }
}

.spinner-container {
  margin-left: 0;

  .spinner-container-bg
  {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.2);
    height: 100%;
    position: fixed;
    z-index: 1041;
    cursor:normal;
    left: 190px;
    right:0;
    top: 46px;
    bottom:0;
    display:flex;
    flex-direction:column;
    justify-content:space-around;

    @media (max-width: 767px) {
      left: 0;
    }

    @media (max-width: 480px) {
      top: 52px;
    }

    @media (max-width: 356px) {
      top: 92px;
    }

  }

}

@keyframes ldio-x13iqk4wcdo {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}

.ldio-x13iqk4wcdo {

	div {
		position: absolute;
		width: 80px;
		height: 80px;
		border: 11px solid #ffffff;
		border-top-color: transparent;
		border-radius: 50%;
		animation: ldio-x13iqk4wcdo 1s linear infinite;
		top: 100px;
		left: 100px;
		box-sizing: content-box;
  }
  
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0;
}

.loadingio-spinner-rolling-90nwviro9wj {
	width: 200px;
	height: 200px;
	display: inline-block;
  overflow: hidden;
  margin:auto;
}


.display-none
{
    display: none ;
}

.hidden
{
    display: none ;
}

.wait-delete
{
    height: 100px;
    z-index: -1;
}

.font_red {
    color:#ce5252;
}

.font_green {
    color:#1aa51a;
}

.font_blue {
    color:#216bca;
}

#flash-field {
    height: 35px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.highlight {
    color: #d21111;
    background: #FFC3C3;
    border: 1px solid #F16A6A;
}

.alert-mixin(@bgcolor, @bordcolor, @color) {
  background-color: @bgcolor;
  border-color: @bordcolor;
  color: @color;
}

.alert-error {
  .alert-mixin(#f2dede, #ebccd1, #a94442);
}
.alert-notice {
  .alert-mixin(#d9edf7, #bce8f1, #31708f);
}

.alert > [role="flash-close"] {
  line-height: 20px;
}

#boxoffice_cart_table td {
    padding:3px;
    padding-left: 8px;
}

table th {
    text-overflow: ellipsis;
}

.discount_checkbox {
    display:inline-block;
}

#box_office_discount_select {
    display:inline-block;
    width:auto;
}

#ticket_div_lightbox {
  cursor:normal;
}

html body div.libocon1, .libocon2, .libocon3, .libocon4, .libocon5, .libocon6{
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.75);
  display: none;
  height: 100%;
  left: -20%;
  position: fixed;
  top: 0;
  width: 120%;
  z-index: 999999;
}

#ticket_div {
  height: 600px;
  top: 0px;
  left: 0;
  right:0;
position:absolute;
}

#success_div > div > #ticket_div {
  height: 510px;
  position:relative;
}

.loady-spinny {
    background-image: url('../spinner_small.gif');
    background-repeat: no-repeat;
    background-position: center center;
}

.contingent-warning {
  background-color: #FFCCCC;
}

.cookie-law {
bottom: 0px;
  position: fixed;
  z-index: 998;
  left: 0;
  text-align: center;
  background: white;
  padding: 1em;
  width: 100%;
  border-top: 2px solid @accordion-border;
  //box-shadow: 0px 0px 5px black;

  & > div {
      padding: 0.25em 0;
  }

  &> .cookie-law--config {
      & > label:not(:last-child) {
          margin-right: 1em;
      }
  }

  &> .cookie-law--submit {
      & > a {
          margin: 0 0.5em;
      }
  }
}

.activity {
    font-size: 14px;
    border-radius: 0;
    padding: 5px;
    margin-bottom: 5px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.margin-right-10 {
    margin-right: 10px;
}

.font-normal {
    font-size: 13px;
    font-weight: normal;
}

#customer-display {
  font-weight: bold;
}

.history-textarea {
    height:200px;
    overflow-y: auto;
}

#history_field {
    height:200px;
    overflow-y: auto;
}

#history_field {
    height:200px;
    overflow-y: auto;
}

// Reservierungen
#box_office_reservation_back {
}

#box_office_reservation_delete_item {
}

.draft_symbol{
  margin-right: 10px;
  color: #ee7d26;
  cursor: pointer;
}

#test_title {
  color: red;
  margin-left: 10px;
}

.nav-list {
  display: flex;
  flex-direction: column;
  height: calc(~"100vh - 45px");

  .no-hover() {
    &:before {
      content: unset;
    }
  }

  .separator {
    flex-grow: 1;
    flex-shrink: 1;
    .no-hover();
  }

  #admin-buttons {
    padding-top: 10px;
    padding-bottom: 10px;
    .no-hover();

    & > form > div {
      & > input, & > button {
        width: calc(~"100% - 20px");
      }
    }
  }
}

.sidebar-scroll .nav-wrap {
  max-height: unset !important;
}

.menu-min {
  .nav-list {
    #admin-buttons {
      form {
        input, button {
          padding: 0;
          text-indent: 100%;
          overflow: hidden;
          margin-top: 9px;
          margin-bottom: 9px;
        }
        .with-tooltip(@text) {
          &:hover {
            position: relative;
            &:before {
              position: absolute;
              left: 42px;
              z-index: 1025;
              width: 176px;
              height: 41px;
              line-height: 38px;
              padding-left: 12px;
              border: 1px solid;
              content: @text;
              background-color: #f5f5f5;
              border-color: #e8e8e8;
              box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
            }
          }
        }

        &.admin--restart-server {
          .with-tooltip('Server neustarten');
        }
        &.admin--test-mode--start, &.admin--test-mode--stop {
          .with-tooltip('Testmodus umschalten');
        }
        &.admin--maintainance--start, &.admin--maintainance--stop {
          .with-tooltip('Benachrichtigungen');
        }
      }
    }
  }
}

#admin-buttons .btn-primary{
  margin: 5px 10px 5px 10px;
  color: #FFF;
}

#admin-buttons .btn-warning{
  margin: 5px 10px 5px 10px;
  color: #000;
}

#form-content-pane > div {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.marg-top-10 {
  margin-top: 10px;
}

.information-tooltip {
  margin-left: 5px;
}

.information-tooltip + .tooltip {
  min-width: 170px;
}

.information-tooltip + .tooltip > .tooltip-inner {
  text-align: left;
  background-color: #ee7d26;
}

.title-div {
  flex-basis: 100%;
  width: 100%;
  font-weight: bold;
}

.additional-field-container {
  background-color: #e5e5e5;
}


#flash_container .alert {
  border: none;
  border-bottom: 1px solid;
  padding: 7px 15px 6px 15px;

  &.alert-error, &.alert-danger {
    border-bottom-color: #ebccd1;
  }
  &.alert-success {
    border-bottom-color: #d6e9c6;
  }
  &.alert-warning {
    border-bottom-color: #faebcc;
  }
  &.alert-info, &.alert-notice {
    border-bottom-color: #bce8f1;
  }
}

.tab-pane {
  word-break: break-word;
}

#edit-condition-pane {
  .col-xs-1, .col-xs-2, .col-xs-3,
  .col-xs-4, .col-xs-5, .col-xs-6,
  .col-xs-7, .col-xs-8, .col-xs-9 {
    padding-right: 0;
  }
}

.strono_text {
  text-decoration: line-through;
}

.event-time-picker {
  label:not(.input-group-addon) {
    line-height: 34px;
  }
}

#edit-information-pane {
  .mce-tinymce iframe {
    height: 220px !important;
  }

  .event_edit_image.error {
    .ace-file-container {
      border-color: #a94442;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
      color: #d5d5d5;
      background: pink;
    }
  }
}


.widget-pos-overview {
  .widget-header {
    padding: 0 15px;
  }
}

.widget-box {
  .list-inline {
    & > .active {
      border-bottom: 4px solid;
      padding-bottom: 7px;
      margin-bottom: -22px;
    }
  }

  .widget-separator {
    height: 0;
    border-top: 1px solid;
    border-color: #e8e8e8;
    padding-left: 0;
    padding-right: 0;
  }

  .with-padding-top {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .widget-color(@color) {
    @widget-class:~`"widget-color-@{color}"`;
    @widget-bg:~`"widget-@{color}"`;
    @widget-cl:@@widget-bg;

    &.@{widget-class} {
      .widget-separator {
        border-color: @widget-cl;
      }

      .widget-background {
        background-color: #FCFCFC;;

        .list-inline {
          & > .active {
            border-bottom-color: @widget-cl;
          }
        }
      }
    }
  }

  .widget-color(~"blue");
  .widget-color(~"blue2");
  .widget-color(~"blue3");
  .widget-color(~"green");
  .widget-color(~"green2");
  .widget-color(~"green3");
  .widget-color(~"red");
  .widget-color(~"red2");
  .widget-color(~"red3");
  .widget-color(~"purple");
  .widget-color(~"pink");
  .widget-color(~"grey");
}


.btn-group:not(.btn-group-dropdown) > .btn {
  border-radius: 3px !important;
}

.btn-group.btn-group-dropdown > .btn:first-child {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}

.btn-group.btn-group-dropdown > .btn:not(:last-child) {
    margin-right: 0 !important;
}

.btn-group.btn-group-dropdown > .btn:last-child,
.btn-group.btn-group-dropdown > .btn.dropdown-toggle-split, {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.admin--messages {
  .mt-8 {
    margin-top:8px;
  }
  .mt-4 {
    margin-top:4px;
  }

  .admin--messages--templates {
    display: inline-block;

    .admin--messages--templates--list {
      & > li > a {
        width: 400px;
        white-space: normal;
      }
    }
  }
}

.v-ace-switch-6 {
  span:first-child {
    padding-right: 3px;
  }
  input[type=checkbox],input[type=radio] {
    &.ace.ace-switch.ace-switch-6 {
      &:first-child + .lbl {
        margin-left: 0 !important;
      }

      & + .lbl {
        vertical-align: middle;
        top: auto !important;
        padding-right: 0 !important;

        &:last-child {
          margin-right: 0 !important;
        }

        & + span {
          padding-left: 3px;
        }

        &::before {
          border-radius: 3px;
        }

        &::after {
          border-radius: 2px !important;
        }
      }
    }
  }
}

.ace-switch + .lbl.pull-right {
  margin-right: 0;
  padding-right: 0;
}

.form-height-fix {
  line-height: 34px;
  min-height: 34px;
}

.mt-7 {
  margin-top: 7px;
}

body table tr.has-error > td {
    color: #a94442;
    background-color: #f2dede !important;
    border-color: #ebccd1;
}

body > .tooltip {
  position: absolute;
  z-index: 2000;
}


.task-list {
  & > .task-list-body {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: -7px;

    & > .task {
      position: relative;

      padding-right: 20px;
      margin-left: -15px;
      margin-right: -15px;

      list-style-type: none;

      & > .task--spinner {
        position: absolute;
        padding-right: 6px;
        right: 0;

        & > span {
            padding-right: 12px;
        }
      }

      &:last-child {
        padding-bottom: 6px;
      }
    }
  }
}


#payment_and_delivery {
  clear: both;
}

.user-menu {
  max-height: 80vh;
  overflow-x: auto;
  min-width: 300px;
}

.t-download {
  margin-top: 1px;
  margin-left: -25px;
}

.pos-design-color {
  width: 44px !important;
  display: inline-block;
}

canvas#viewport_canvas {
    width: auto !important;
}

.page-header {
  h1 {
    color: initial;

    i {
      color: #ee7d26;
    }
  }
} 

.nav-pills {
  margin-left: 0;
  position: relative;
}

.nav-pills>li>a {
  background-color: #f7f7f7;
  color: #444;
}

.nav-pills>li.active>a, .nav-pills>li.active>a:hover, .nav-pills>li.active>a:focus, .nav>li>a:hover, .nav>li>a:focus {
  color: @theme-color-main;
  background: #fcfcfc;
}

.nav-pills:not(.nav-stacked)>li.active>a:after {
  border-bottom: 3px solid @theme-color-main;
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  right: 0;
}
.nav-pills.nav-stacked>li.active>a:after {
  border-right: 3px solid @theme-color-main;
  position: absolute;
  content: " ";
  bottom: 0;
  left: 0;
  top: 0;
}

.nav-pills:not(.nav-stacked) + .tab-content {
  margin-top: 1.25rem;
}

.nav-tabs > li:only-child, .nav-pills > li:only-child {
  display: none !important;
}
.nav-pills:not(.nav-stacked)>li+li {
  margin-left: 5px;
}
.nav-pills.nav-stacked>li+li {
  margin-top: 5px;
}

.actions {
  :not(td) {
    padding-top: 0.5rem;
  }

  a {
    padding: 0;
    margin-right: 0.25rem;

    :not(.text-danger):not(.text-success) {
      color: #444;

      &:hover {
        color: @theme-color-main;
      }
    }
  }
}

a.action {
  padding: 0;
  margin-right: 0.25rem;

  :not(.text-danger):not(.text-success) {
    color: #444;

    &:hover {
      color: @theme-color-main;
    }
  }
}

.form-input-heading {
    padding: 0 !important;
}

.form-input-heading select {
    background: transparent;
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
    margin: 0px;
}

.form-input-heading select+* {
    padding-left: 4px;
}

.delivery-no-margin-checkbox {
    table {
        width: 100%;
    }

    th:not(:first-child) {
        text-align: center;
    }

    .checkbox {
        text-align: center;
        padding-left: 0;
        margin-top: 0;

        span {
            padding-right: 0;
        }
    }
}

#form-content-target {
    #form-content-table {
        margin-bottom: 0;
    }

    .move-tbl-margin {
        margin-bottom: 20px;
    };
}

.fa-chevron-collapse {
    &.collapsed {
        &::after {
            content: "\f078";
        }
    }
    &::after {
        content: "\f077";
    }
}

.dropdown-menu.dropdown-menu-right {
    left: unset;
    right: 0;
}

.propose-new-comment {
    background-color: transparent !important;
    border-left: none !important;
}

.propose-new-comment > .widget-body {
    border-width: 0;
    background-color: transparent !important;
}
.propose-new-comment .comment-submit {
    margin-top: 5px;
}

.no-padding-right {
    padding-right: 0 !important;
}

.input-group-select {
    margin: -6px -12px;
    background: transparent !important;
    border: 0;
    padding: 0 12px;
}


.form-list-mixin() {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    &.form-list-pad {
        padding: 1em;
    }

    & > label:not(:first-child), & > .grid-key:not(:first-child) {
        padding-top: 1em;
        width: 100%;
        margin-bottom: 0 !important;
    }
    & > input, & > select, & > .grid-value {
        width: 100%;
    }
}

.form-grid-mixin() {
    &.wrap-xs {
        @media (max-width: @screen-xs-min) {
            .form-list-mixin()
        }
    }

    &.wrap-sm {
        @media (max-width: @screen-sm-min) {
            .form-list-mixin()
        }
    }

    &.wrap-md {
        @media (max-width: @screen-md-min) {
            .form-list-mixin()
        }
    }
}

.multi-column() {
    display: grid;
    column-gap: 1em;
    grid-template-rows: repeat(2, max-content);

    .form-grid-mixin();

    & > label, & > .grid-key {
        grid-row: 1;
        align-self: top;
    }
    & > label + .grid-value {
        margin-bottom: 0.25em;
    }
    & > input, & > select, & > .grid-value {
        grid-row: 2;
    }
}

.form-list {
    .form-list-mixin();
}

.form-60-40 {
    .multi-column();
    grid-template-columns: 6fr 4fr;
}

.form-40-60 {
    .multi-column();
    grid-template-columns: 4fr 6fr;
}

.form-50-50 {
    .multi-column();
    grid-template-columns: repeat(2, 1fr);
}

.form-33-33-33 {
    .multi-column();
    grid-template-columns: repeat(3, 1fr);
}


.form-table-2 {
    grid-template-columns: 20% 1fr;
    grid-row-gap: 0.25em;

    display: grid;
    padding: 0.5em 1em;
    align-items: baseline;

    & > label, & > .grid-key {
        grid-column: 1;
        align-self: start;
        padding-top: 5px;
    }
    & > label + .grid-value {
        margin-bottom: 0.25em;
    }
    & > input, & > select, & > .grid-value {
        grid-column: 2;
    }

    .form-grid-mixin();
}

.page-grid-widgets {
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
    grid-row-gap: 1em;
    grid-column-gap: 1em;

    @media (max-width: 1400px) {
        // grid-template-columns: repeat(2, auto 1fr);
        grid-row-gap: 0.5em;
        grid-column-gap: 0.5em;
    }

    @media (max-width: 970px) {
        display: flex;
        flex-direction: column;
    }
}

.login .page-container {
    margin-left: 0;
    margin-right: 0;
}
.login-container {
    // background-color: #e1e1e1;

    .login-box {
        border: 1px solid #e1e1e1 !important;
        border-radius: 4px;
        box-shadow: 0px 0px 6px #e1e1e1 !important;
        padding: 2em 0 1em !important;
        background: white;

        h4 {
            padding: 0.25em 0;
            margin-top: 0;
            margin-bottom: 0;
        }

        .space {
            margin: 6px 0 !important;
        }

        .widget-body {
            background: transparent;
        }
    }
}

.choose-mfa {
    .header {
        margin-bottom: 0;
        padding-left: 0.5em;
    }
    button {
        display: flex;
        background: transparent;
        border: 0;
        width: 100%;
        gap: 0.5em;
        padding: 0.75em;
        border-bottom: 1px solid rgba(0,0,0,0.25);
        &:hover {
            background: rgba(0,0,0,0.15);
        }
        text-align: left;
        align-items: middle;

        & > i.fa {
            width: 20px;
            height: 20px;
            font-size: 20px !important;
        }

        & > span {
            flex: 1 1;
        }

        &:after {
            display: block;
            content: "\f054";
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            width: 14px;
            height: 14px;
            line-height: 20px;
            font-size: 14px !important;
        }
    }

    .factor:last-child button {
        border-bottom: 0;
    }
}


#datatable_wrapper #filters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .form-control {
        margin-right: 0;
    }

    margin-bottom: 2em;
}

.modal_manual_size(@sz) {
  width: @sz !important;
  @media (max-width: @sz + 50px) {
    width: unset !important;
  }
}

.modal_manual_big {
    .modal_manual_size(800px);
}

.modal_manual_very_big {
  .modal_manual_size(1200px);
}

.box-office-header {
    display: flex;
    justify-content: space-between;

    & > .lhr {
        padding-left: 15px;
    }

    & > .rhr {
        padding-right: 15px;
        flex: 1 1;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        flex-direction: row-reverse;

        & > div {
            width: 100%;
        }
    }
}

.input-group .form-control ~ .input-group-btn > .btn {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

html:has(.no-shell), body.no-shell {
    height: 100%;

    .spinner-container {
        & > .spinner-container-bg {
            left: 0;
            top: 0;
        }
    }

    .main-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .main-content {
        flex: 1 1;
        margin: 0;
        padding: 0;

        & > .page-container {
            height: 100%;
        }
    }
}

#help-edit {
    display: flex;
    padding-inline: 1em;
    gap: 1em;

    &:empty {
        display: none;
    }

    & > button {
        place-self: center;
        height: 34px;
        line-height: 30px;
    }
}


.nav > li > a.danger {
    background-color: #d22020;
    color: white;
}
