@import (reference) "./ace.less";







.lighter { font-weight: lighter; }
.bolder { font-weight: bolder; }
.inline { display: inline-block !important; }
.block { display: block !important; }

.center , .align-center{ text-align: center !important; }
.align-left { text-align: left !important; }
.align-right { text-align: right !important; }

.middle { vertical-align: middle; }
.align-middle { vertical-align: middle !important; }
.align-top { vertical-align: top !important; }
.align-bottom { vertical-align: bottom !important; }

.position-relative, .pos-rel { position: relative; }
.position-absolute, .pos-abs { position: absolute; }

.no-float { float: none !important; }

.line-height-normal { line-height: normal !important; }
.line-height-0 { line-height: 0 !important; }
.line-height-1 { line-height: 1 !important; }
.line-height-125 { line-height: 1.25 !important; }
.line-height-150 { line-height: 1.5 !important; }


.dark { color: @ace-dark !important; }
.white { color: @ace-white !important; }
.red { color: @ace-red !important; }
.red2 { color: @ace-red2 !important; }
.light-red { color: @ace-light-red !important; }
.blue { color: @ace-blue !important; }
.light-blue { color: @ace-light-blue !important; }
.green { color: @ace-green !important; }
.light-green { color: @ace-light-green !important;}
.orange { color: @ace-orange !important; }
.orange2 { color: @ace-orange2 !important; }
.light-orange {color: @ace-light-orange !important;}
.purple { color: @ace-purple !important; }
.pink { color: @ace-pink !important; }
.pink2 { color: @ace-pink2  !important; }
.brown { color: @ace-brown !important; }
.grey { color: @ace-grey !important; }
.light-grey { color: @ace-light-grey !important; }


.biggerX (@index) when (@index < 310) {
	.bigger-@{index} { font-size: unit(@index,~"%") !important; }
	.biggerX(@index + 10);
}
.biggerX(110);
.bigger-125 { font-size: 125% !important;}
.bigger-175 { font-size: 175% !important; }
.bigger-225 { font-size: 225% !important; }
.bigger-275 { font-size: 275% !important; }

.smallerX (@index) when (@index > 10) {
	.smaller-@{index} { font-size: unit(@index,~"%") !important; }
	.smallerX(@index - 10);
}
.smallerX(90);
.smaller-75 { font-size: 75% !important;}

.widthX (@index) when (@index < 105) {
	.width-@{index} { width: unit(@index,~"%") !important; }
	.widthX(@index + 5);
}
.widthX(20);
.width-75 { width: 75% !important;}
.width-25 { width: 25% !important;}
.width-auto { width: auto !important; }
.height-auto { height: auto !important; }

.no-padding { padding: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-margin { margin: 0 !important; }
.no-margin-bottom { margin-bottom: 0 !important; }
.no-margin-top { margin-top: 0 !important; }
.no-margin-left { margin-left: 0 !important; }
.no-margin-right { margin-right: 0 !important; }

.no-border { border-width: 0; }
.no-border-bottom { border-bottom-width: 0; }
.no-border-top { border-top-width: 0; }
.no-border-left { border-left-width: 0; }
.no-border-right { border-right-width: 0; }

/**
.no-underline {
 text-decoration: none !important;
}
.no-hover-underline:hover {
 text-decoration: none !important;
}
.no-outline:focus {
 outline: none !important;
}

.no-box-shadow {
 .box-shadow(none) !important;
}


.overflow-hidden {overflow: hidden !important;}
.overflow-auto {overflow: auto !important;}
.overflow-scroll {overflow: scroll !important;}
.overflow-visible {overflow: visible !important;}
*/

.no-radius {
 .border-radius(0) !important;
}
.no-text-shadow {
 text-shadow:none !important;
}

//<hr /> like spacing by using div, e.g <div class="hr hr-32"></div>
.hr {
 display:block;
 height: 0;
 overflow: hidden;
 font-size: 0;
 border-width: 1px 0 0 0;
 border-top: 1px solid #E3E3E3;
 margin: 12px 0;
 border-top-color: rgba(0,0,0,0.11);
}
.hr-double {
 height: 3px;
 border-top: 1px solid #E3E3E3;
 border-bottom: 1px solid #E3E3E3;
 
 border-top-color: rgba(0,0,0,0.11);
 border-bottom-color: rgba(0,0,0,0.11);
}
.hr.dotted, .hr-dotted { border-style: dotted;}

.hrX (@index) when (@index > 0) {
	.hr-@{index}, .hr@{index} { margin:unit(@index,px) 0; }
	.hrX(@index - 2);
}
.hrX(32);


//some spacing classes, use like <div class="space-24"></div>
.space() {
	max-height: 1px;
	min-height: 1px;
	overflow: hidden;
}
.space { .space(); margin: 12px 0; }
[class*="vspace-"] { display: none;} //visible only on smaller devices where columns are stacked on top of each other

.spaceX (@index) when (@index > 0) {
	.space-@{index} { .space(); margin: unit(@index,px) 0 unit(@index - 1,px); }
	[class*="vspace-@{index}"]
	{
		.space();
		margin:unit(@index,px) 0 unit(@index - 1,px) 0;
	}
	.spaceX(@index - 2);
}
.spaceX(32);//produce spaces
.space-0 , [class*="vspace-0"] { .space(); margin: 0; }

@media only screen and (max-width: @screen-tiny-max) {
	[class*="vspace-"][class*="-xs"] {
		display: block;
	}
}
@media only screen and (max-width: @screen-xs-max) {
	[class*="vspace-"][class*="-sm"] {
		 display: block;
	}
}
@media only screen and (max-width: @screen-sm-max) {
	[class*="vspace-"][class*="-md"] {
		 display: block;
	}
}
@media only screen and (max-width: @screen-md-max) {
	[class*="vspace-"][class*="-lg"] {
		 display: block;
	}
}




//headers
.header {
 line-height: 28px;
 margin-bottom: 16px;
 margin-top: 18px;
 padding-bottom: 4px;
 border-bottom: 1px solid #e8e8e8;
 
 &.blue {
	border-bottom-color: desaturate(lighten(@ace-blue, 35%), 10%);
 }
 &.green {
	border-bottom-color: desaturate(lighten(@ace-green, 35%), 6%);
 }
 &.purple {
	border-bottom-color: desaturate(lighten(@ace-purple, 28%), 5%);
 }
 &.orange {
	border-bottom-color: desaturate(lighten(@ace-orange, 25%), 5%);
 }
 &.orange2 {
	border-bottom-color: desaturate(lighten(@ace-orange2, 20%), 5%);
 }
 &.red {
	border-bottom-color: desaturate(lighten(@ace-red, 30%), 5%);
 }
 &.grey {
	border-bottom-color: desaturate(lighten(@ace-grey, 30%), 5%);
 }
 &.pink {
	border-bottom-color: desaturate(lighten(@ace-pink, 32%), 5%);
 }
 &.pink2 {
	border-bottom-color: desaturate(lighten(@ace-pink2, 20%), 5%);
 }
 &.light-blue {
	border-bottom-color: desaturate(lighten(@ace-light-blue, 15%), 5%);
 }
 &.light-red {
	border-bottom-color: desaturate(lighten(@ace-light-red, 15%), 5%);
 }
 &.light-green {
	border-bottom-color: desaturate(lighten(@ace-light-green, 15%), 5%);
 }
 &.brown {
	border-bottom-color: desaturate(lighten(@ace-brown, 32%), 5%);
 }

  > .@{icon} {
	margin-right: 2px;
  }
}




.action-buttons a {
  margin: 0 3px;
  display: inline-block;
  opacity: 0.85;
   .transition(~"all 0.1s");

  &:hover {
	text-decoration: none;
	opacity: 1;
	.transform(~"scale(1.2)");
  }
}
