.edit-list {
  margin-top: 1em;

  .edit-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 5px;
    border-bottom: 1px solid #EEE;

    & > div {
      display: inline-block;
    }

    .edit-list-name {
      vertical-align: middle;
      margin-left: 0.5em;
    }

    .edit-list-color {
      width: 20px;
      height: 20px;
    }

    .edit-list-filler {
      flex-grow: 1;
      flex-shrink: 1;
    }

    .edit-list-config {
      justify-self: flex-end;

      .edit-list-config-action {
        cursor: pointer;
      }
    }
  }
}
