body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

label {
  font-weight: bold;
}

.label {
  padding-top: 4px;
}

#ui-datepicker-div {
  border: none;
  z-index: 9999999 !important;
}

.breadcrumb>li+li:before {
  content: ">> " !important;
}

table.display {
  margin: 0 auto;
  width: 100% !important;
  clear: both;
  border-collapse: collapse;
  word-wrap: break-word
}

.sorting_1 {
  background-color: #f0f0f0 !important;
}

.event_template {
  width: 95%;
}

.badge-important {
  background-color: #b94a48;
}

.breadcrumb {
  margin-bottom: 0px !important;
}

.bootstrap-timepicker-widget.dropdown-menu.open {
  display: inline-block;
}

.col-xs-2 .radio,
.col-xs-2 .checkbox {
  padding-left: 0;
}

.dropdown-menu>li>a {
  font-size: 13px;
  padding-left: 11px;
  padding-right: 11px;
  margin-bottom: 1px;
  margin-top: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ace-nav>li>a {
  background: #FFF;
  color: initial;
  display: block;
  line-height: inherit;
  text-align: left;
  height: 100%;
  width: auto;
  min-width: 50px;
  padding: 7px 33px 8px 12px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.ace-nav>li>a>.ace-icon {
  display: inline-block;
  font-size: 16px;
  color: initial;
  text-align: center;
  width: 20px;
  position: absolute;
  right: 8px;
  bottom: 11px;
}

.ace-nav>li>a:hover,
.ace-nav>li>a:focus,
.ace-nav>li.open>a,
.ace-nav>li.open>a:hover {    
  background: #f7f7f7 !important;
  color: initial !important;
}

.nav-list>li>a>.menu-icon {
  vertical-align: bottom !important;
}

.lightgray>a:hover,
.lightgray>a:focus {}

.blocked {
  opacity: 0.25;
}

.glyphicon-remove:hover {
  color: red !important;
}

.glyphicon-pencil:hover {
  color: blue;
}

.glyphicon-ok:hover {
  color: green;
}

.modal-dialog-tmng {
  width: 85% !important;
  max-width: 800px;
  min-width: 287px;
  margin: 30px auto !important;
}

#veranstaltungsliste {
  margin-bottom: 0;
}

.bLink:link,
.bLink:visited,
.bLink:hover,
.bLink:active,
.bLink:focus {
  text-decoration: none;
  color: black;
}

.tags {
  border: none;
  width: 100%;
}

.tags input[type="text"],
.tags input[type="text"]:focus {
  background-color: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: #858585;
  font-family: inherit;
  font-size: 14px;
  padding: 5px 4px 6px;
  transition-duration: 0.1s;
  width: 100%;
}

.ui-autocomplete {
  z-index: 9999 !important;
}

textarea {
  line-height: 1.4 !important;
}

#payment-use-own-settings-panel .panel-body .nav li a img {
  opacity: 0.4;
}

#payment-use-own-settings-panel .panel-body .nav li.active a img {
  opacity: 1.0;
}

#delivery-method-custom-settings-table tr.unconfirmed td {
  background-color: #f2dede;
}

#lastschrift.alert.alert-success,
#kreditkarte.alert.alert-success,
#sofortueberweisung.alert.alert-success,
#pay_pal.alert.alert-success {
  width: 25%;
}

#payment-confirmation-edit-modal input {
  width: 90%;
}

.alert-container {
  float: left;
  width: 100%;
}

.alert-container .alert {
  float: left;
}

.toggle-payment-settings:hover~.payment-settings,
.toggle-pending-payments:hover~.pending-payments {
  box-shadow: 0 0 0 5px rgba(20, 10, 40, 0.1);
}

tr.grey {
  background: none repeat scroll 0 0 #cecece;
  color: grey;
}

.rotate-0 {
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.rotate-45 {
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.rotate-90 {
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-135 {
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.rotate-180 {
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-225 {
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.rotate-270 {
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.rotate-315 {
  -moz-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  -o-transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}

#contextMenu li,
#blockContextMenu li {
  padding: 5px;
}

#box-office-event-list-table tbody tr {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.form-group .billing_date {
  text-align: right;
  float: left;
  width: 50%;
}

.btn-group.organizer_select_container {
  width: 30%;
}

#organizer_select {
  width: 100%;
}

#organizer_select .caret {
  margin-top: -10px;
  margin-left: 2px;
  float: right;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.spinner {
  width: 70px;
  text-align: center;
  opacity: 0.7;
}

.spinner>div {
  width: 18px;
  height: 18px;
  background-color: #2693FF;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
  animation: bouncedelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0.0)
  }

  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes bouncedelay {

  0%,
  80%,
  100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }

  40% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.overlay_canvas {
  position: absolute;
  z-index: 100;
}

.remove-row {
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-top: 2px;
}

/*kassenstyle*/

.buttonrow {
  margin-bottom: 20px;
  margin-top: 10px;
}

.bgtable {
  background: #E1E1E1;
  padding: 10px;
  border: 1px solid white;
}

.table {
  background: white;
  border: 1px solid #ebebeb;
}

.leftbtn {
  left: -15px !important;
}

.rightbtn {
  right: -15px !important;
}

.ligrey {
  background: #F7F7F7;
  padding: 10px;
  border-top: none !important;

}

.liblue {
  background: #F7F7F7;
  padding: 10px;

}

/*.boxgrey{
	border:1px solid white;
	background:lightgrey;
	padding-left: 15px;
	border-top:none;
}*/
.arenaplan {
  margin-top: 0px;
}

.lbl {
  top: -2px !important;
  position: relative;
  padding-right: 15px;
}

.showsteh {
  display: none;
}

.libo1,
.libo2 {
  border: 30px solid white;
  border-radius: 4px;
  position: fixed;
  top: 20%;
  left: 11%;
  width: 78%;
  z-index: 1000000;
  padding: 0 0px 0 0px;
  display: none;
  min-width: 850px;
  background: white;
}

html body div.libocon1,
.libocon2 {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.75);
  display: none;
  height: 100%;
  left: -20%;
  position: fixed;
  top: 0;
  width: 120%;
  z-index: 999999;
}

html body div.libo1 .liblue,
.libo2 .liblue,
html body div.libo1 .ligrey,
.libo2 .ligrey {
  max-width: 96%;
}

.libo-header,
.liboclosex {
  margin-top: 9px;
}

.liboclosex>i {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  line-height: 18px;
  margin: 0px;

}

.bold {
  font-weight: bold;
  font-size: 17px;
}


.sbutt {
  height: 17px;
  left: 53px;
  position: absolute;
  width: 17px !important;


}

.sbutt>i {
  height: 17px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: auto !important;
  z-index: 9999
}

.sbutt:hover {
  display: none !important;
}

.ace-spinner {
  width: 70px !important;
}

.zoom-in,
.zoom-out,
.reset {
  border: medium none;
  height: 50px;
  line-height: 50px;
  margin-left: -11px;
  margin-right: -11px;
  width: 50px;
  background: none;
}

.zoom-in {
  line-height: 21px;
  font-size: 30px;
}

.zoom-out {
  line-height: 64px;
  font-size: 25px;
}

.reset {
  line-height: 64px;
  font-size: 20px;
}

@media screen and (min-width:1224px) {
  .panzoom-view-port {
    /*left: -20% !important;*/
    position: relative;
    /*top: -165px;*/
    /*width: 140% !important;*/
    -khtml-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .panzoom-buttons {
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    z-index: 99;
    padding: 0px 15px 0 0;

    /* border-radius: 0px 0px 5px 0px;*/
    top: 0 !important;
  }

  .seatingchartoverviewimg {
    visibility: visible;
  }
}

@media screen and (max-width:1224px) {
  .panzoom-buttons {
    top: 30px;

  }

  .seatingchartoverviewimg {
    display: none
  }
}

.panzoom-buttons:hover {
  z-index: 190;
}

.arenaplan>.container {
  max-width: 100%;
}

.pull-right>i {
  margin-right: 10px;
}

.glyphicon-edit,
.fa-times {
  cursor: pointer;
}

.buttonrow .back {
  display: none;
}

.pay_box {
  display: none;
}

.liboclosex>i {
  margin-right: 0 !important;
  cursor: pointer;
}

.liboclose>i,
.libosteh>i,
.libositz>i,
.liboclose {
  cursor: pointer;
}

.martop5 {
  margin-top: 5px;
}

/*end kassenstyle*/

.likelabel {
  font-size: 14px;
  font-weight: 700;
}

.bootstrap-duallistbox-container .info {
  display: none;
}

.waiting-container {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  cursor: normal;
}

.waiting-container img {
  top: 50%;
  transform: translate(0, -50%);
  position: absolute;
}

.hidden {
  display: none;
}

.column-collapsed {
  visibility: collapse;
}

.label-xlg.arrowed-in-right {
  margin-right: 7px;
  display: inline-block;
  padding: 0.3em 0.7em 0.4em;
  font-size: initial;
  font-weight: normal;
  line-height: initial;
}

* {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

table>thead>tr {
  background-image: none !important;
}

.dataTable>thead>tr>th.sorting_desc,
.dataTable>thead>tr>th.sorting_asc {
  background-color: #f0f0f0;
  color: #555;
}

.navbar {
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.navbar-default .navbar-brand {
  font-size: 15pt;
}


.navbar:not(.navbar-collapse) .ace-nav>li:first-child {
  border: none !important;
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.no-skin .navbar .navbar-toggle {
  background: none;
  margin-top: 6px;
  border: none !important;
}

.no-skin .navbar .navbar-toggle:hover,
.no-skin .navbar .navbar-toggle:focus,
.no-skin .navbar .navbar-toggle.display,
.no-skin .navbar .navbar-toggle[data-toggle=collapse]:not(.collapsed) {
  background: rgba(0, 0, 0, 0.1) !important;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background-color: #EEE;
  background-image: none;
  border: 1px solid #DDD;
  text-align: center;
}

.pad-15 {
  padding: 7px;
}

.pad-left-40 {
  padding-left: 40px;
}

#filter-container {
  padding-top: 20px;
}

h4>.glyphicon {
  font-size: 15px;
}

.hover-row {
  padding-bottom: 2px;
  padding-top: 4px;
  margin-top: 1px;
  margin-bottom: 2px;
}

.hover-row:hover {
  background: #e6e6e6;
}

.right-line:after {
  content: "";
  background: #d5d5d5;
  position: absolute;
  right: -1px;
  width: 1px;
  height: 130%;
  top: 0;
}

#delivery_method_radio>label,
#payment_method_radio>label {
  margin-bottom: -3px;
}

.boxoffice_userdata {
  margin-bottom: 3px;
}

.modal-layer-2 {
  z-index: 1042 !important;
}

.modal-large {
  max-width: 1000px;
}

.darker_grey {
  background-color: #DDD;
}

.panzoom-view-port {
  margin: 30px auto;
}


.dt-buttons .buttons-collection:before {
  content: "\f107";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  /*--adjust as necessary--*/
  color: inherit;
  font-size: 18px;
  padding-right: 0.5em;
  position: absolute;
  top: 3px;
  right: 0;
}

.dt-buttons .buttons-collection {
}

input[type=checkbox].ace.ace-switch+.lbl[data-lbl]::before,
input[type=checkbox].ace.ace-switch+.lbl[data-lbl]::after {
  font-family: arial;
}

.force_white {
  background-color: #FFF !important;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: text !important;
  color: #555 !important;
  border-color: #d0d0d0 !important;
}

.booking-history-notification {
  max-height: 21px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  color: #004480;
  position: absolute;
  text-overflow: ellipsis;
  width: 91%;
  margin: 5px;
  margin-top: -12px;
}

.infobox>.infobox-icon>.ace-icon:before {
  padding-top: 8px;
}

.date-range-selector {}

.date-range-selector-item {
  padding-bottom: 6px;
}

.date-range-selector input {
  width: 100%;
}

input.validate-error {
  border-color: #e4a1a1 !important;
  background-color: #ffecec !important;
}

.modal-second-layer {
  z-index: 1041;
}

.validate-error-tab,
.error-tab,
.hint-tab {
  border-top-color: #e23232 !important;
  color: #cc0000 !important;
}

.dashboard-value {
  font-size: 16px;
}

.dashboard-tab-icon {
  display: inline-block;
  width: 35px;
  text-align: center;
}

.dashboard-tab-icon>i {
  font-size: 15pt;
  vertical-align: top;
  margin-top: -14px;
  margin-left: -7px;
}

.dashboard-tab-data {
  display: inline-block;
}

.dashboard-filter-container {
  margin-bottom: 12px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
}

.tabs-left .tab-content {
  padding: 8px 13px;
  overflow-x: hidden;
  min-height: 300px;
}

#dashboard-tabs a {
  min-height: 52px;
  min-width: 150px;
}

.datepicker {
  border-radius: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.pad-left-40 {
  padding-left: 40px;
}

.dashboard_ajax_event_item {
  border-bottom: 1px solid #ebebeb;
  padding: 10px 15px;
  display: block;
}

.dashboard_ajax_event_item h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard_ajax_event_item span {
  color: #666;
}

.dashboard_chart_width {
  width: 150px !important;
}

#dashboard_compare_events_container {
  position: relative;
  min-height: 80px;
}

.chart {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 110px;
  text-align: center;
  padding-top: 34px;
  font-weight: bold;
}

.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.percent {
  display: inline-block;
  line-height: 110px;
  z-index: 2;
}

.percent:after {
  content: '%';
  margin-left: 0.1em;
  font-size: .8em;
}

.chart span {
  font-size: 8pt;
  line-height: 11px;
  display: block;
  padding-top: 2px;
  font-weight: normal;
}

.aufdruck_container.col-sm-12 {
  padding: 10px 0px 10px 15px;
}

.main-content>.page-container>#flash_container {
  margin-left: -15px;
  margin-right: -15px;
}

input[type=checkbox].ace.ace-switch+.lbl[data-lbl]::before {
  border-radius: 3px;
  box-shadow: none;
  height: 24px;
  line-height: 22px;
  text-indent: -6px;
  width: 80px;
  font-size: 12px;
  background: #EEE;
}

input[type=checkbox].ace.ace-switch:checked+.lbl::before {
  background-color: #ee7d26;
  border-color: #2a80b9;
  text-indent: 13px;
}

input[type=checkbox].ace.ace-switch.btn-empty+.lbl::after {
  border-radius: 3px;
  box-shadow: 0px 0px 0px 1px rgb(213, 213, 213);
  top: 1px;
  height: 22px;
  left: 1px;
  width: 38px;
  border-radius: 3px 0px 0px 3px;
}

input[type=checkbox].ace.ace-switch:checked+.lbl::after {
  left: 42px;
  border-radius: 0px 3px 3px 0px;
}

.page-container {
  margin: 0 15px;
}

.tabbed {
  padding-left: 1em;
  border-left: 1px dotted #999;
}

.page-header h1 {
  margin: 8px 0px;
}

.page-header .admin_organizer {
  margin-top: 5px;
  margin-bottom: -5px;
}

.page-content,
.page-header {
  padding: 20px 15px 10px 15px;
  background: transparent;
}

legend {
  margin-bottom: 10px;
}

@media only screen and (max-width: 490px) {
  .navbar:not(.navbar-collapse) .navbar-header.pull-right {
    float: right !important;
    display: block;
  }

  .navbar:not(.navbar-collapse) .navbar-header.pull-left {
    float: left !important;
    display: block;
  }

  .navbar-fixed-top+.main-container {
    padding-top: 45px !important;
  }

  .navbar:not(.navbar-collapse) .ace-nav {
    background-color: transparent !important;
  }
}


@media only screen and (max-width: 600px) {
  .navbar:not(.navbar-collapse) .navbar-brand {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .ace-nav>li>a {
    width: 119px;
  }
}

.helpdesk-setting-label {
  width: 120px;
  text-align: left;
}

.booking-rowx {
  border-radius: 2px 2px 2px 2px;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border: 3px solid #a6a6a6;
}

#helpdesk_list thead {
  display: none;
}

#helpdesk_list tr {
  margin-bottom: 35px;
  display: block;
  border: 1px solid #DDD;
  border-top: 0;
}

#helpdesk_list tr:last-child {
  margin-bottom: 0;
}

#helpdesk_list tr .dataTables_empty {
  display: block;
}

#helpdesk_list td .details-div {
  border-top: 1px solid #DDD;
  border-bottom: none;
  padding-bottom: 3px;
}

#helpdesk_list td:not(.dataTables_empty) {
  padding: 0;
  display: flex;
}

.booking-row,
.booking-row:hover {
  padding: 8px;
  color: #393939;
}

.truncate div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-striped.cancelled>tbody>tr.cancelled {
  background: #f2dede;
  color: #b53822;
  border: 1px solid #e6bfbf;
}

.table-striped.cancelled>tbody>tr.cancelled>td {
  background: #f2dede;
}

.table-striped>tbody>tr.cancelled {
  color: #b53822;
}

.booking-table-totals {
  width: 100%;
  max-width: 215px;
}

.booking-table-totals thead {
  border-bottom: 1px solid #ebebeb;
}

.booking-table-totals tfoot {
  border-top: 1px solid #ebebeb;
}

.selected-text {
  padding-top: 8px;
  color: #a59999;
}

.selected-text>.selected-number {
  font-weight: bold;
}

table.table-accordion-closed tbody {
  display: none;
}

table .table-accordion {
  cursor: pointer;
  background: #F7F7F7;
  font-weight: bold;
  text-align: center;
}

.table-accordion.toggler::before {
  font-family: FontAwesome;
  color: #494949;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 8px;
}

table:not(.table-accordion-closed) .table-accordion.toggler::before {
  content: "\f077";
}

table.table-accordion-closed .table-accordion.toggler::before {
  content: "\f078";
}

.panel-heading .accordion-toggle:after {
  /* symbol for "opening" panels */
  font-family: 'Glyphicons Halflings';
  /* essential for enabling glyphicon */
  content: "\e114";
  /* adjust as needed, taken from bootstrap.css */
  float: right;
  /* adjust as needed */
  color: grey;
  /* adjust as needed */
  margin-top: 10px;
}

.panel-heading .accordion-toggle.active:after {
  content: "\e113";
}

.panel-body {
  margin-top: 15px;
}

.panel-title>a>i {
  color: #2a80b9;
}

span.capitalize {
  display: inline-block;
}

.capitalize::first-letter {
  /* text-transform: uppercase; */
}

.table>thead>tr>th {
  border-bottom-width: 1px;
}

.table>thead>tr.no-border>th {
  border: 0;
  padding-bottom: 2px;
  font-weight: normal;
}

.table>thead>tr.no-border:last-child>th {
  padding-bottom: 8px;
  padding-top: 2px;
}

.text-line-through {
  text-decoration: line-through;
}

.form-group .input-group-input-addon {
  background: #eee;
}

.b3-form-inliner {
  padding: 0px;
  width: 0px;
  border: none;
}

.weekDays-selector input {
  display: none!important;
}

.weekDays-selector input[type=checkbox] + label {
  display: inline-block;
  border-radius: 100px;
  background: #EEE;
  height: 28px;
  width: 28px;
  margin-right: 3px;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  font-size: 0.75em;
}

.weekDays-selector input[type=checkbox]:checked + label {
  background: #ee7d26;
  color: #ffffff;
}

option:disabled {
  background: #f9f9f9;
  color: #AAA;
}

.w50 {
 width:50px;
}

.vertical-spacer {
  margin-top:15px;
}

.new {
  -webkit-animation: update 2s;
  animation: update 2s;
}

.fc-dayGrid-view .fc-body .fc-row {
  min-height: 6em !important;
  height: auto !important;
}

a.fc-event {
  padding:4px;
}

.fc-past {
  background: #F7F7F7;
}

@-webkit-keyframes update {
  from {
    background: #fff5ce;
  }

  to {
    background: #F7F7F7;
  }
}

@keyframes update {
  from {
    background: #fff5ce;
  }

  to {
    background: #F7F7F7;
  }
}

#coupon_mass_assign{
  opacity: 0;
  width: 102%;
  height: 106%;
  position: absolute;
  left: -1px;
  top: -1px;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.nav-list > li {
  border: none !important;
}

.main-container {
  background: #FDFDFD;
}
.pagination>li {
  display: inline-block;
  margin: 2px;
}

.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
  border-top: 1px solid #ebebeb;
}

.no-skin .sidebar {
  border: none !important;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.1);
}
.pagination > li > a, .pager > li > a {
  border-width: 0 !important;
  border-radius: 3px !important;
}

button, .btn {
  box-shadow: none !important;
}

.navbar {
  height: 45px;
  min-height: 45px;
}

@media (max-width: 479px) {
  .navbar:not(.navbar-collapse) .ace-nav > li {
    margin-top: -1px !important;
  }
}

@media (max-width: 345px) {
    .navbar-header > img {
        display: none;
    }
}
