@import (reference) "./ace.less";
@import (reference) "./fixes.less";






.enable_plugin_datatables() when(@enable-plugin-datatables = true) {

//dataTables
.dataTables_length {
  //margin-left:8px;
  select {
	width: 70px;
	height: 25px;
	padding: 2px 3px;
  }
  label {
	font-weight:normal;
  }
}
.dataTables_filter {
 // margin-right:8px;
  text-align:right;
  input[type=text] {
	width: 125px;
	height: 18px;
	line-height: 18px;
	.box-sizing(content-box);
	padding: 4px 6px;
  }
  label {
	font-weight:normal;
  }
}

.dataTables_info {
  float: left;
  line-height: 30px;
}

.dt-button-mixin() {
  padding: 6px 12px;
  border: 1px solid #DDD;
  background-color: #f7f7f7;
  color: #333;
  pointer: pointer;
  user-select: none;

  &.current {
      color: white;
      background: #ee7d26;
      border-top-color: #ee7d26;
      border-bottom-color: #ee7d26;
  }

  border-right: 0;

  &.disabled {
      background-color: #f1f1f1;
      color: #999;
  }
}

.dataTables_paginate {
  text-align: right;
  margin: 0 12px;
  padding: 12px 0px;
  float: right;

  & .previous {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
  }

  & .next {
      border-right: 1px solid #DDD !important;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
  }

  & .paginate_button, & .ellipsis {
      .dt-button-mixin();
  }
}

.dataTables_length {
    float: left;
    line-height: 44px;

    label {
        margin-bottom: 0;
    }

    select {
        display: inline-block;
        height: 30px;
        margin: 2px;
    }
}


.dt-button-collection {
    position: absolute !important;
    z-index: 2002;
    display: inline-flex;
    flex-direction: row;
    width: 300px;

    & .dt-button {
        display: flex;
        flex-direction: row;
        & > a {
            flex: 1 1;
            &:focus {
                background: unset;
                color: unset;
            }
            &:hover {
                background: unset;
                color: unset;
            }
        }
        &:hover {
            background: #ee7d26;
            color: #fff;
        }
        &.dt-button-active-a::after {
          content: "\f00c";
          font: normal normal normal 14px/1 FontAwesome;
          font-size: 14px;
          text-rendering: auto;
          padding-right: 8px;
          padding-top: 6px;
        }
    }
}
.dt-buttons {
    position: relative;
    & > .btn-default {
        padding-right: 25px;
    }
}


#dataTables_wrapper {
  label {
	display: inline-block;
	font-size: @baseFontSize;
  }
  input[type=text] , select {
	margin-bottom: 0 !important;
	margin: 0;
  }
  select {
    margin-right: 1rem;
  }

  .row {

	&:first-child {
		padding-top: 12px;
		padding-bottom: 12px;

		+ .dataTable {
			border-top: 1px solid @table-border-color;
			border-bottom: 1px solid @table-border-color;
		}
	}
	&:last-child {
		border-bottom: 1px solid lighten(@table-border-color , 1%);
		padding-top: 12px;
		padding-bottom: 12px;
	}
  }

  .dataTables_scroll + .row {
	border-top: 1px solid lighten(@table-border-color , 1%);
  }

}




.dataTable {
  margin-bottom:0;
  > thead > tr > th {
    &[class*=sort] {
		cursor:pointer;
		&:after {
			float: right;
			display: inline;
			content: "\f0dc";
			font-family: FontAwesome;
			font-size: 13px;
			font-weight: normal;
                opacity:1;
                color:#BBB;
		}

		&:hover {
			color:@table-sort-hover;
		}
	}

	&[class*=sorting_] {
	}

	&.sorting_desc , &.sorting_asc {
    }

	&.sorting_desc:after {
		content:"\f0dd";
		top:-6px;
		color:#666;
	}
	&.sorting_asc:after {
		content:"\f0de";
		top:4px;
		color:#666;
	}

	&.sorting_disabled {
		cursor:inherit;
		&:after { display:none; }
	}

  }

}

//scrollable dataTable
.dataTables_scrollHead + .dataTables_scrollBody > .dataTable > thead > tr > th:after {
	display: none;
}
.dataTables_scrollHeadInner {
  width: auto !important;
}
.dataTables_scrollHeadInner > .dataTable > thead > tr > th {
  border-bottom-width: 0 !important;
}


//some extra border because of scrolling horizontally
.dataTables_borderWrap {
 .dataTables_scrollBody , .dataTables_scrollHead {
	border: 1px solid @table-border-color !important;
	border-width: 0 1px !important;

	.table-bordered {
		border-left-width: 0;
		border-right-width: 0;

		> thead > tr > th:first-child,
		> tbody > tr > td:first-child {
			border-left-width: 0;
		}
		> thead > tr > th:last-child,
		> tbody > tr > td:last-child {
			border-right-width: 0;
		}
	}
 }
}





//some extra from
//https://github.com/DataTables/Plugins/tree/master/integration/bootstrap/3
table.dataTable {
	clear: both;
	max-width: none !important;
}

table.dataTable th:active {
	outline: none;
}

//Scrolling
div.dataTables_scrollHead table {
	margin-bottom: 0 !important;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

div.dataTables_scrollHead table thead tr:last-child th:first-child,
div.dataTables_scrollHead table thead tr:last-child td:first-child {
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

div.dataTables_scrollBody table {
	border-top: none;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

div.dataTables_scrollBody tbody tr:first-child th,
div.dataTables_scrollBody tbody tr:first-child td {
	border-top: none;
}

div.dataTables_scrollFoot table {
	margin-top: 0 !important;
	border-top: none;
}


}
.enable_plugin_datatables();
