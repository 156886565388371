.navbar .navbar-toggle {
	border: 1px solid transparent;
	
	width: 40px;
	height: 35px;
	line-height: 0;
	
	padding:0 0 1px;
	text-align: center;
	
	&.pull-right {
		margin: 5px 6px 4px 0;
	}
	&.pull-left {
		margin: 5px 0 4px 6px;
	}
	
	&:active {
		top: 1px;
	}

	&.navbar-toggle-img {
		width: auto;
		height: auto;
		overflow: hidden;
		padding: 0;
		
		border-width: 0;
		> img {
			border-radius: inherit;
			max-height: 35px;
			border-width: 0;
		}
	}

	.icon-bar {   
		border-radius: 0;
		background-color: #333;
		width: 20px;
		margin-top: 2px;
		margin-bottom: 2px;
		margin-left: 10px;
	}
}


