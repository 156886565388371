@import (reference) "./ace.less";






/* custom animated icons */
.icon-animated-bell {
  display: inline-block;
  .animation(~"ringing 2.0s 5 ease 1.0s");  
  .transform-origin(~"50% 0%");
}

@-moz-keyframes ringing {
  0% { -moz-transform: rotate(-15deg);}
  2% { -moz-transform: rotate(15deg);}
  4% { -moz-transform: rotate(-18deg);}
  6% { -moz-transform: rotate(18deg);}
  8% { -moz-transform: rotate(-22deg);}
  10% { -moz-transform: rotate(22deg);}
  12% { -moz-transform: rotate(-18deg);}
  14% { -moz-transform: rotate(18deg);}
  16% { -moz-transform: rotate(-12deg);}
  18% { -moz-transform: rotate(12deg);}
  20% { -moz-transform: rotate(0deg);}
}

@-webkit-keyframes ringing {
  0% { -webkit-transform: rotate(-15deg);}
  2% { -webkit-transform: rotate(15deg);}
  4% { -webkit-transform: rotate(-18deg);}
  6% { -webkit-transform: rotate(18deg);}
  8% { -webkit-transform: rotate(-22deg);}
  10% { -webkit-transform: rotate(22deg);}
  12% { -webkit-transform: rotate(-18deg);}
  14% { -webkit-transform: rotate(18deg);}
  16% { -webkit-transform: rotate(-12deg);}
  18% { -webkit-transform: rotate(12deg);}
  20% { -webkit-transform: rotate(0deg);}
}

@-ms-keyframes ringing {
  0% { -ms-transform: rotate(-15deg);}
  2% { -ms-transform: rotate(15deg);}
  4% { -ms-transform: rotate(-18deg);}
  6% { -ms-transform: rotate(18deg);}
  8% { -ms-transform: rotate(-22deg);}
  10% { -ms-transform: rotate(22deg);}
  12% { -ms-transform: rotate(-18deg);}
  14% { -ms-transform: rotate(18deg);}
  16% { -ms-transform: rotate(-12deg);}
  18% { -ms-transform: rotate(12deg);}
  20% { -ms-transform: rotate(0deg);}
}

@keyframes ringing {
  0% { transform: rotate(-15deg);}
  2% { transform: rotate(15deg);}
  4% { transform: rotate(-18deg);}
  6% { transform: rotate(18deg);}
  8% { transform: rotate(-22deg);}
  10% { transform: rotate(22deg);}
  12% { transform: rotate(-18deg);}
  14% { transform: rotate(18deg);}
  16% { transform: rotate(-12deg);}
  18% { transform: rotate(12deg);}
  20% { transform: rotate(0deg);}
}



.icon-animated-vertical  {
  display: inline-block;
  .animation(~"vertical 2.0s 5 ease 2.0s");
}

@-moz-keyframes vertical {
  0% { -moz-transform: translate(0,-3px);}
  4% { -moz-transform: translate(0,3px);}
  8% { -moz-transform: translate(0,-3px);}
  12% { -moz-transform: translate(0,3px);}
  16% { -moz-transform: translate(0,-3px);}
  20% { -moz-transform: translate(0,3px);}
  22% { -moz-transform: translate(0,0);}
}
@-webkit-keyframes vertical {
  0% { -webkit-transform: translate(0,-3px);}
  4% { -webkit-transform: translate(0,3px);}
  8% { -webkit-transform: translate(0,-3px);}
  12% { -webkit-transform: translate(0,3px);}
  16% { -webkit-transform: translate(0,-3px);}
  20% { -webkit-transform: translate(0,3px);}
  22% { -webkit-transform: translate(0,0);}
}
@-ms-keyframes vertical {
  0% { -ms-transform: translate(0,-3px);}
  4% { -ms-transform: translate(0,3px);}
  8% { -ms-transform: translate(0,-3px);}
  12% { -ms-transform: translate(0,3px);}
  16% { -ms-transform: translate(0,-3px);}
  20% { -ms-transform: translate(0,3px);}
  22% { -ms-transform: translate(0,0);}
}
@keyframes vertical {
  0% { transform: translate(0,-3px);}
  4% { transform: translate(0,3px);}
  8% { transform: translate(0,-3px);}
  12% { transform: translate(0,3px);}
  16% { transform: translate(0,-3px);}
  20% { transform: translate(0,3px);}
  22% { transform: translate(0,0);}
}



.icon-animated-hand-pointer  {
  display: inline-block;
  .animation(~"hand-pointer 2.0s 4 ease 2.0s");
}

@-moz-keyframes hand-pointer {
  0% { -moz-transform: translate(0,0);}
  6% { -moz-transform: translate(5px,0);}
  12% { -moz-transform: translate(0,0);}
  18% { -moz-transform: translate(5px,0);}
  24% { -moz-transform: translate(0,0);}
  30% { -moz-transform: translate(5px,0);}
  36% { -moz-transform: translate(0,0);}
}









.icon-animated-wrench {
  display: inline-block;
  .animation(~"wrenching 2.5s 4 ease");
  .transform-origin(~"90% 35%");
}

@-moz-keyframes wrenching {
  0% { -moz-transform: rotate(-12deg);}
  8% { -moz-transform: rotate(12deg);}

  10% { -moz-transform: rotate(24deg);}
  18% { -moz-transform: rotate(-24deg);}

  20% { -moz-transform: rotate(-24deg);}
  28% { -moz-transform: rotate(24deg);}

  30% { -moz-transform: rotate(24deg);}
  38% { -moz-transform: rotate(-24deg);}

  40% { -moz-transform: rotate(-24deg);}
  48% { -moz-transform: rotate(24deg);}

  50% { -moz-transform: rotate(24deg);}
  58% { -moz-transform: rotate(-24deg);}

  60% { -moz-transform: rotate(-24deg);}
  68% { -moz-transform: rotate(24deg);}

  75% { -moz-transform: rotate(0deg);}
}

@-webkit-keyframes wrenching {
  0% { -webkit-transform: rotate(-12deg);}
  8% { -webkit-transform: rotate(12deg);}

  10% { -webkit-transform: rotate(24deg);}
  18% { -webkit-transform: rotate(-24deg);}

  20% { -webkit-transform: rotate(-24deg);}
  28% { -webkit-transform: rotate(24deg);}

  30% { -webkit-transform: rotate(24deg);}
  38% { -webkit-transform: rotate(-24deg);}

  40% { -webkit-transform: rotate(-24deg);}
  48% { -webkit-transform: rotate(24deg);}

  50% { -webkit-transform: rotate(24deg);}
  58% { -webkit-transform: rotate(-24deg);}

  60% { -webkit-transform: rotate(-24deg);}
  68% { -webkit-transform: rotate(24deg);}

  75% { -webkit-transform: rotate(0deg);}
}

@-o-keyframes wrenching {
  0% { -o-transform: rotate(-12deg);}
  8% { -o-transform: rotate(12deg);}

  10% { -o-transform: rotate(24deg);}
  18% { -o-transform: rotate(-24deg);}

  20% { -o-transform: rotate(-24deg);}
  28% { -o-transform: rotate(24deg);}

  30% { -o-transform: rotate(24deg);}
  38% { -o-transform: rotate(-24deg);}

  40% { -o-transform: rotate(-24deg);}
  48% { -o-transform: rotate(24deg);}

  50% { -o-transform: rotate(24deg);}
  58% { -o-transform: rotate(-24deg);}

  60% { -o-transform: rotate(-24deg);}
  68% { -o-transform: rotate(24deg);}

  75% { -o-transform: rotate(0deg);}
}

@-ms-keyframes wrenching {
  0% { -ms-transform: rotate(-12deg);}
  8% { -ms-transform: rotate(12deg);}

  10% { -ms-transform: rotate(24deg);}
  18% { -ms-transform: rotate(-24deg);}

  20% { -ms-transform: rotate(-24deg);}
  28% { -ms-transform: rotate(24deg);}
  
  30% { -ms-transform: rotate(24deg);}
  38% { -ms-transform: rotate(-24deg);}

  40% { -ms-transform: rotate(-24deg);}
  48% { -ms-transform: rotate(24deg);}

  50% { -ms-transform: rotate(24deg);}
  58% { -ms-transform: rotate(-24deg);}

  60% { -ms-transform: rotate(-24deg);}
  68% { -ms-transform: rotate(24deg);}

  75% { -ms-transform: rotate(0deg);}
}

@keyframes wrenching {
  0% { transform: rotate(-12deg);}
  8% { transform: rotate(12deg);}

  10% { transform: rotate(24deg);}
  18% { transform: rotate(-24deg);}
    
  20% { transform: rotate(-24deg);}
  28% { transform: rotate(24deg);}
 
  30% { transform: rotate(24deg);}
  38% { transform: rotate(-24deg);}

  40% { transform: rotate(-24deg);}
  48% { transform: rotate(24deg);}

  50% { transform: rotate(24deg);}
  58% { transform: rotate(-24deg);}

  60% { transform: rotate(-24deg);}
  68% { transform: rotate(24deg);}

  75% { transform: rotate(0deg);}
}






@-moz-keyframes blinking {
  0% { opacity: 1;}
  40% { opacity: 0;}
  80% { opacity: 1;}
}
@-webkit-keyframes blinking {
  0% { opacity: 1;}
  40% { opacity: 0;}
  80% { opacity: 1;}
}
@-ms-keyframes blinking {
  0% { opacity: 1;}
  40% { opacity: 0;}
  80% { opacity: 1;}
}
@keyframes blinking {
  0% { opacity: 1;}
  40% { opacity: 0;}
  80% { opacity: 1;}
}




@-moz-keyframes pulsating {
  0% { -moz-transform: scale(1);}
  5% { -moz-transform: scale(0.75);}
  10% { -moz-transform: scale(1);}
  15% { -moz-transform: scale(1.25);}
  20% { -moz-transform: scale(1);}
  25% { -moz-transform: scale(0.75);}
  30% { -moz-transform: scale(1);}
  35% { -moz-transform: scale(1.25);}
  40% { -moz-transform: scale(1);}
}
@-webkit-keyframes pulsating {
  0% { -webkit-transform: scale(1);}
  5% { -webkit-transform: scale(0.75);}
  10% { -webkit-transform: scale(1);}
  15% { -webkit-transform: scale(1.25);}
  20% { -webkit-transform: scale(1);}
  25% { -webkit-transform: scale(0.75);}
  30% { -webkit-transform: scale(1);}
  35% { -webkit-transform: scale(1.25);}
  40% { -webkit-transform: scale(1);}
}
@-ms-keyframes pulsating {
  0% { -ms-transform: scale(1);}
  5% { -ms-transform: scale(0.75);}
  10% { -ms-transform: scale(1);}
  15% { -ms-transform: scale(1.25);}
  20% { -ms-transform: scale(1);}
  25% { -ms-transform: scale(0.75);}
  30% { -ms-transform: scale(1);}
  35% { -ms-transform: scale(1.25);}
  40% { -ms-transform: scale(1);}
}
@keyframes pulsating {
  0% { transform: scale(1);}
  5% { transform: scale(0.75);}
  10% { transform: scale(1);}
  15% { transform: scale(1.25);}
  20% { transform: scale(1);}
  25% { transform: scale(0.75);}
  30% { transform: scale(1);}
  35% { transform: scale(1.25);}
  40% { transform: scale(1);}
}
