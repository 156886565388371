@import (reference) "./ace.less";






.enable_infobox() when (@enable-infobox = true) {

//dashboard info and stats mini boxes 
.infobox-container {
 text-align: center;
 font-size: 0;
}
.infobox {

 display: inline-block;
 width: 210px;
 height: 66px;

 color: #555;
 background-color: #FFF;
 box-shadow: none;
 border-radius: 0;
 
 
 margin: -1px 0 0 -1px;

 padding: 8px 3px 6px 9px;
 border: 1px dotted;
 border-color: #D8D8D8 !important;

 vertical-align: middle;
 text-align: left;

 position: relative;
 

   > .infobox-icon {
	display: inline-block;
	vertical-align: top;
 
	width:44px;
	
	 > .@{icon} {
		 display: inline-block;
		 height: 42px;
		 margin: 0;
		 padding: 1px 1px 0 2px;

		 background-color: transparent;
		 border-width: 0;

		 text-align: center; 
		 
		 position: relative;

		 .border-radius(100%); 
		 .box-shadow(~"1px 1px 0 rgba(0,0,0,0.2)");
		 
		 &:before {
			 font-size: @font-size-infobox-icon;
			 display: block;
			 padding: 6px 0 7px;
			 
			 width: 40px;
			 text-align: center;
			 border-radius: 100%;

			 color: #FFF;
			 color: rgba(255, 255, 255, 0.9);

			 background-color: transparent;
			 background-color: rgba(255, 255, 255, 0.2);

		 }
	 }
  }
  
  
  .infobox-content {
	 color: #555;
	 &:first-child {//like in small infoboxes that have no number data etc
		font-weight: bold;
	 }
  }
  
  
  > .infobox-data {
	 display: inline-block;

	 border-width: 0;
	 border-top-width: 0;

	 font-size: @font-size-infobox-data;

	 text-align: left;

	 line-height: 21px;
	 min-width: 130px;
	 
	 padding-left: 8px;
	 
	 position: relative;
	 top: 0;
	 
	 > .infobox-data-number {
		display: block;
		font-size: @font-size-infobox-data-number;
		margin: 2px 0 4px;
	 
		position: relative;
	}
	
	> .infobox-text {
		display: block;
		font-size: @font-size-infobox-text;
		margin: 2px 0 4px;
		 
		position: relative;
		text-shadow: none;
	}
  }


  &.no-border {
		border-width: 0 !important;
  }
}


@media only screen and (max-width: @screen-topbar-down) {
 .infobox {
	min-width: 95%;
	margin-top: 2px;
	margin-bottom: 2px;
 }
}




//infobox color
.infobox-color(@color) {
  @infobox-class:~`"infobox-@{color}"`;
  @infobox-bg:~`"infobox-@{color}"`;
  @infobox-cl:@@infobox-bg;
  
  .@{infobox-class} {
	color: @infobox-cl;
	border-color: @infobox-cl;
	> .infobox-icon > .@{icon}  {
		background-color: @infobox-cl;
	}
	
	.enable_infobox_dark_colors() when (@enable-infobox-dark = true) {
		&.infobox-dark {
			background-color: @infobox-cl;
			border-color: @infobox-cl;
		}
	}
	.enable_infobox_dark_colors();
  }
}
.infobox-color(~"purple");
.infobox-color(~"purple2");
.infobox-color(~"pink");
.infobox-color(~"blue");
.infobox-color(~"blue2");
.infobox-color(~"blue3");
.infobox-color(~"red");
.infobox-color(~"brown");
.infobox-color(~"wood");
.infobox-color(~"light-brown");
.infobox-color(~"orange");
.infobox-color(~"orange2");
.infobox-color(~"green");
.infobox-color(~"green2");
.infobox-color(~"grey");
.infobox-color(~"black");





.enable_infobox_dark() when(@enable-infobox-dark = true) {
.infobox-dark {
  margin: 1px 1px 0 0;
  border-color: transparent !important;
  border-width: 0;
  
  color: #FFF;
  padding: 4px;
  > .infobox-icon > .@{icon} {
	& , &:before {
	  background-color: transparent;
	  box-shadow: none !important;//to override .rtl's
	  text-shadow: none;
	  border-radius: 0;
	  font-size: @font-size-infobox-dark-icon;
	}
	&:before {
		.opacity(1);
	}
  }
  
  .infobox-content {
	color: #FFF;	 
  }

}

}
.enable_infobox_dark();




.infobox {
  > .infobox-progress {
	padding-top: 0;
	display: inline-block;
	vertical-align: top;
 
	width: 44px;
  }

  > .infobox-chart {
	padding-top: 0;
	display: inline-block;
	vertical-align: text-bottom;
	width: 44px;
	text-align: center;
	
	> .sparkline {
		font-size: @font-size-infobox-sparkline;
	}
	canvas {
		vertical-align: middle !important;
	}
  }
  
  
  //stat trend indicators and badges
  > .stat {
		display: inline-block;
		position: absolute;
		right: 20px;
		top: 11px;

		text-shadow: none;
		color: #ABBAC3;
		
		font-size: @font-size-infobox-stat;
		font-weight: bold;

		padding-right: 18px;
		padding-top: 3px;
		
		&:before {
			display: inline-block;
			content: "";
			 
			width: 8px;
			height: 11px;
			background-color:#ABBAC3;
			 
			position: absolute;
			right: 4px;
			top: 7px;
		}
		&:after {
			display: inline-block;
			content: "";
 
			position: absolute;
			right: 1px;
			top: -8px;
 
			border: 12px solid transparent;
			border-width: 8px 7px;
			border-bottom-color: #ABBAC3;
		}
		
		&.stat-success {//pointing up
			color: #77C646;
			&:before {
				background-color: #77C646;
			}
			&:after {
				border-bottom-color: #77C646;
			}
		}
		
		&.stat-important {/*pointing down*/
			color: #E4564F;
			&:before {
				background-color: #E4564F;
				top: 3px;
			}
			&:after {
				border-top-color: #E4564F;
				border-bottom-color: transparent;
				bottom: -6px;
				top: auto;
			}
		}
  }
  
  .enable_infobox_dark_stats() when (@enable-infobox-dark = true) {
	  &.infobox-dark > .stat {
		color: #FFF;
		&:before {
			background-color: #E1E5E8;
		}
		&:after {
			border-bottom-color: #E1E5E8;
		}
		
		&.stat-success {
			color: #FFF;
			&:before {
				background-color: #D0E29E;
			}
			&:after {
			 border-bottom-color: #D0E29E;
			}
		}
		&.stat-important {
			color: #FFF;
			&:before {
				background-color: #FF8482;
				top: 3px;
			}
			&:after {
				border-top-color: #FF8482;
				border-bottom-color: transparent;
				bottom: -6px;
				top: auto;
			}
		}
	  }
  }
  .enable_infobox_dark_stats();

  
  > .badge {
		position: absolute;
		right: 20px;
		top: 11px;
		border-radius: 0;
		 
		text-shadow: none;
		color: #FFF;

		font-size: @font-size-infobox-badge;
		font-weight: bold;
		
		line-height: 15px;
		height: 16px;
			
		padding: 0 1px;	
  }
  
  .enable_infobox_dark_badge() when (@enable-infobox-dark = true) {
	  &.infobox-dark > .badge {
			color: #FFF;
			background-color: rgba(255,255,255,0.2) !important;
			border: 1px solid #F1F1F1;

			top: 2px;
			right: 2px;

			&.badge-success > .@{icon}{
				color: #C6E9A1;
			}
			&.badge-important > .@{icon}{
				color: #ECB792;
			}
			&.badge-warning > .@{icon}{
				color: #ECB792;
			}
	  }
  }
  .enable_infobox_dark_badge();

}




.infobox-small {
	width: 135px;
	height: 52px;
	text-align: left;
 
	padding-bottom: 5px;
	
	> .infobox-icon , > .infobox-chart , > .infobox-progress {
		display: inline-block;
		width: 40px;
		max-width: 40px;
		height: 42px;
		line-height: 38px;
		 
		vertical-align: middle; 
	}
	
	> .infobox-data {
		display: inline-block;
		text-align: left;
		 
		vertical-align: middle;
		max-width: 72px;
		min-width: 0;
	}
	> .infobox-chart > .sparkline {
		font-size: floor(@font-size-infobox-sparkline * 0.6);
		margin-left: 2px;
	}
}



}
.enable_infobox();


.percentage {
  font-size: @baseFontSize + 1;
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
  
  .infobox-small & {
		font-size: @baseFontSize;
		font-weight: normal;
		margin-top: 2px;
		margin-left: 2px;
   }
}



