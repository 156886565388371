#dashboard_compare_events {
  .chart_edit_button {
    position: absolute;
    bottom: 40px;

    &:active {
      top: unset;
    }
  }

  .chart_time_select_description {
    position: absolute;
    right: 0;
    bottom: 10px;
    pointer-events:none;
    display: block;
    width: ~"calc(100% - 180px)";
    text-align: center;
  }

  .amcharts-stock-div {
    margin-bottom: 40px;
  }

  .amcharts-compare-div {
    // overflow: hidden !important;

    .amcharts-compare-item-div {
      height: 20px !important;

      &>div:nth-child(2) {
        text-overflow: ellipsis;
      }
    }
  }
}


