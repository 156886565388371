/*!
 * Datetimepicker for Bootstrap v3
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
.bootstrap-datetimepicker-widget {
  top: 0;
  left: 0;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  border-radius: 4px;
}
.bootstrap-datetimepicker-widget.timepicker-sbs {
  width: 600px;
}
.bootstrap-datetimepicker-widget.bottom:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -7px;
  left: 7px;
}
.bootstrap-datetimepicker-widget.bottom:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  top: -6px;
  left: 8px;
}
.bootstrap-datetimepicker-widget.top:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #ccc;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: -7px;
  left: 6px;
}
.bootstrap-datetimepicker-widget.top:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
  position: absolute;
  bottom: -6px;
  left: 7px;
}
.bootstrap-datetimepicker-widget .dow {
  width: 14.2857%;
}
.bootstrap-datetimepicker-widget.pull-right:before {
  left: auto;
  right: 6px;
}
.bootstrap-datetimepicker-widget.pull-right:after {
  left: auto;
  right: 7px;
}
.bootstrap-datetimepicker-widget > ul {
  list-style-type: none;
  margin: 0;
}
.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  width: 100%;
  font-weight: bold;
  font-size: 1.2em;
}
.bootstrap-datetimepicker-widget table[data-hour-format="12"] .separator {
  width: 4px;
  padding: 0;
  margin: 0;
}
.bootstrap-datetimepicker-widget .datepicker > div {
  display: none;
}
.bootstrap-datetimepicker-widget .picker-switch {
  text-align: center;
}
.bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0;
}
.bootstrap-datetimepicker-widget td,
.bootstrap-datetimepicker-widget th {
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
.bootstrap-datetimepicker-widget td.day:hover,
.bootstrap-datetimepicker-widget td.hour:hover,
.bootstrap-datetimepicker-widget td.minute:hover,
.bootstrap-datetimepicker-widget td.second:hover {
  background: #eeeeee;
  cursor: pointer;
}
.bootstrap-datetimepicker-widget td.old,
.bootstrap-datetimepicker-widget td.new {
  color: #999999;
}
.bootstrap-datetimepicker-widget td.today {
  position: relative;
}
.bootstrap-datetimepicker-widget td.today:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.bootstrap-datetimepicker-widget td.active,
.bootstrap-datetimepicker-widget td.active:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.bootstrap-datetimepicker-widget td.active.today:before {
  border-bottom-color: #fff;
}
.bootstrap-datetimepicker-widget td.disabled,
.bootstrap-datetimepicker-widget td.disabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget td span {
  display: block;
  width: 47px;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 2px;
  cursor: pointer;
  border-radius: 4px;
}
.bootstrap-datetimepicker-widget td span:hover {
  background: #eeeeee;
}
.bootstrap-datetimepicker-widget td span.active {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.bootstrap-datetimepicker-widget td span.old {
  color: #999999;
}
.bootstrap-datetimepicker-widget td span.disabled,
.bootstrap-datetimepicker-widget td span.disabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget th.switch {
  width: 145px;
}
.bootstrap-datetimepicker-widget th.next,
.bootstrap-datetimepicker-widget th.prev {
  font-size: 21px;
}
.bootstrap-datetimepicker-widget th.disabled,
.bootstrap-datetimepicker-widget th.disabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget thead tr:first-child th {
  cursor: pointer;
}
.bootstrap-datetimepicker-widget thead tr:first-child th:hover {
  background: #eeeeee;
}
.input-group.date .input-group-addon span {
  display: block;
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.bootstrap-datetimepicker-widget.left-oriented:before {
  left: auto;
  right: 6px;
}
.bootstrap-datetimepicker-widget.left-oriented:after {
  left: auto;
  right: 7px;
}
.bootstrap-datetimepicker-widget ul.list-unstyled li div.timepicker div.timepicker-picker table.table-condensed tbody > tr > td {
  padding: 0px !important;
}
