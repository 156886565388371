@highlight-menu-border-color: #C8D8E2;
@highlight-menu-active-bg: #F2F6F9;
@highlight-h-sidebar-color: lighten(#4D96CB, 10%);


.enable_highlight_active_no_skin() when(@enable-highlight-active = true) {
 //hide submenu active item caret
 .nav-list li.highlight li.active > a,
 .nav-list li li.highlight.active > a {
	&:after , &:before {
		display: none !important;
	}
 }

 //show first-level active item caret
 .nav-list > li.highlight.active > a {
	&:after, &:before {
		display: block !important;
	}
 }
 
 //hide active item caret inside scrollbars
 .sidebar-scroll {
   .nav-list > li.active.highlight > a {
	&:after, &:before {
		display: none !important;
	}
   }
 }
 


//only for default body.no-skin
.no-skin {

.nav-list > li.highlight {
 &:hover , &.active {
	border-color: @highlight-menu-border-color;
	+ li {
		border-top-color: @highlight-menu-border-color;
	}
	> a {
		background-color: #FFF;
	}
 }

 &.active {
	&:after {
		display: none;
	}
	&:before {
		display: block;
		background-color: darken(@nav-item-hover-indicator , 5%);
	}
 
	> a  {
		background-color: @highlight-menu-active-bg !important;
		&:before, &:after {
			display: block;
			content: "";
			position: absolute;

			top: -1px;
			right: -1*ceil(@nav-item-height / 4);
			bottom: auto;
			z-index: 1;

			border-style: solid;
			border-width: ceil(@nav-item-height / 2) 0 ceil(@nav-item-height / 2)+1 ceil(@nav-item-height / 4);
			border-color: transparent;
		}
		&:before {
			border-left-color: darken(@highlight-menu-border-color, 12%);
			-moz-border-left-colors: darken(@highlight-menu-border-color, 12%);
			
			right: -1*ceil(@nav-item-height / 4) - 1;
		}
		&:after {
			border-left-color: @highlight-menu-active-bg;
			-moz-border-left-colors: @highlight-menu-active-bg;
		}
	}
 }
}




//don't display the active item caret for submenu items, show just for the parent .nav-list > li item
.nav-list li li.highlight.active > a {
	background-color: @highlight-menu-active-bg;
}
.nav-list li li.highlight.active.open > a {
	background-color: #FFF;
	&:hover {
		background-color: @submenu-item-background-hover;
	}
}



.enable_horizontal_menu_highlight_no_skin() when(@enable-horizontal-menu = true) {
 @media (min-width: @grid-float-breakpoint) {
 .sidebar.h-sidebar {
	.nav-list > li.highlight.active > a:after {
		display: block;
		content: "";
		position: absolute;
		
		left: 0;
		top: auto;
		right: auto;
		bottom: -2px;
		
		left: 50%;
		margin-left: -7px;
		
		border-color: transparent;
		-moz-border-right-colors: none;
		-moz-border-left-colors: none;
		-moz-border-top-colors: none;

		border-width: 8px 7px;
		border-bottom-color: #FFF;
		-moz-border-bottom-colors: #FFF;
	 }
	 .nav-list > li.highlight.active > a:before {
	 	display: block;
		content:"";
		position: absolute;
		
		display: block;
		left: 0;
		top: auto;
		right: auto;
		
		bottom: -1px;
		
		left: 50%;
		margin-left: -7px;
		
		border-width: 8px;

		border-color: transparent;
		-moz-border-right-colors: none;
		-moz-border-left-colors: none;
		-moz-border-top-colors: none;

		border-bottom-color: @highlight-h-sidebar-color;
		-moz-border-bottom-colors: @highlight-h-sidebar-color;
	 }
	 
	 .nav-list > li.highlight.active:hover > a {
		&:after, &:before {
			display: none;
		}
	 }
  }

 }
}
 .enable_horizontal_menu_highlight_no_skin();

}


.enable_compact_menu_highlight_no_skin() when (@enable-compact-menu = true) {
 .sidebar.compact .nav-list li.highlight.active > a {
	&:after , &:before {
		display: none !important;
	}
 }

 .enable_horizontal_menu_highlight_compact() when(@enable-horizontal-menu = true) {
  @media (min-width: @grid-float-breakpoint) {
   .sidebar.h-sidebar {
	.nav-list li.highlight.active > a {
		&:after , &:before {
			display: block !important;
			border-width: 8px !important;//to override .rtl .menu-min
			
			-moz-border-left-colors: none !important;//~
			-moz-border-right-colors: none !important;
			
			border-left-color: transparent !important;
			border-right-color: transparent !important;
		}
	 }
	.nav-list > li.highlight.active:hover > a {
		&:after, &:before {
			display: none !important;
		}
	 }
   }
  }
 }
 .enable_horizontal_menu_highlight_compact();


}
.enable_compact_menu_highlight_no_skin();


.menu_min_highlight_no_skin() {
	.nav-list > li.highlight.active > a {
		&:after , &:before {
			display: block !important;//to override .compact
			top: -1px;
			border-width: ceil(@nav-item-height / 2) 0 ceil(@nav-item-height / 2)+1 ceil(@nav-item-height / 4);
		}
	}
}


.enable_sidebar_collapse_highlight_no_skin() when (@enable-sidebar-collapse = true) {
 .sidebar.menu-min {
	.menu_min_highlight_no_skin();
 }
}
.enable_sidebar_collapse_highlight_no_skin();

.enable_minimized_responsive_menu_highlight_no_skin() when (@enable-minimized-responsive-menu = true) {
 @media (max-width: @grid-float-breakpoint-max) {
	.sidebar.responsive-min {
		.menu_min_highlight_no_skin();
	}
 }
}
.enable_minimized_responsive_menu_highlight_no_skin();

}

.enable_highlight_active_no_skin();