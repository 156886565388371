@import (reference) "./ace.less";






/** buttons */
.btn {
 display: inline-block;

 background-image:none !important;
 border: 1px solid #FFF;
 box-shadow: none !important;

 .transition(~"all ease .15s");

 cursor: pointer;

 vertical-align: middle;
 margin: 0;

 position: relative;

 &:not(.btn-default) {
	color: #FFF !important;
 }
 &.button-default {
	 color: initial !important;
 }
}

.btn-lg {
	border-width: 1px;
	line-height: 1.35;
    padding: 7px 16px;
}
.btn-sm {
	border-width: 1px;
	font-size: @font-size-btn-sm;
	padding: 4px 9px;
	line-height: 1.39;
}
.btn-xs {
	border-width: 1px;
}
.btn-minier {
	padding: 0 4px;
	line-height: 18px;
	border-width: 1px;
	font-size: @font-size-btn-minier;
}

button.btn:active {
	top: 1px;
	//left: 1px;
	//using both left & top, will cause chrome not to catch click events at seldom times
}



.enable_default_buttons() when(@enable-default-buttons = true) {

//button color
.btn-color(@color1, @color2) {
  & , &:focus {
	background-color:@color1 !important;
	border-color:@color2;
  }

  &:hover , &:active, .open &.dropdown-toggle {
	background-color:@color2 !important;
	border-color:@color2 !important;
  }
  &.no-border:hover , &.no-border:active {
	border-color:@color2;
  }
  &.no-hover:hover , &.no-hover:active {
	background-color:@color1 !important;
  }
  &.active {
	background-color: mix(@color1,@color2) !important;
	border-color: darken(mix(@color1,@color2),7%);
  }
  &.no-border.active {
    background-color: darken(mix(@color1,@color2),3%) !important;
	border-color: darken(mix(@color1,@color2),3%);
  }
  &.disabled, &[disabled], fieldset[disabled] & {
	&,
    &:hover,
    &:focus,
    &:active,
    &.active {
		background-color:@color1 !important;
		border-color:@color1;
	}
  }
}

.btn-color(@color-name) {
 @color1-name : ~`"btn-@{color-name}"`;
 @color2-name : ~`"btn-@{color-name}-hover"`;

 .btn-color(@@color1-name, @@color2-name);
}


.btn , .btn-default {
	.btn-color(~"default");
}
.btn-primary {
	.btn-color(~"primary");
}
.btn-secondary {
	.btn-color(~"grey");
}
.btn-info {
	.btn-color(~"info");
}
.btn-info2 {
	.btn-color(~"info2");
}
.btn-success {
	.btn-color(~"success");
}
.btn-warning {
	.btn-color(~"warning");
}
.btn-danger {
	.btn-color(~"danger");
}
.btn-inverse {
	.btn-color(~"inverse");
}
.btn-pink {
	.btn-color(~"pink");
}
.btn-purple {
	.btn-color(~"purple");
}
.btn-grey {
 .btn-color(~"grey");
}

.btn-yellow {
 .btn-color(~"yellow");
 color:@btn-yellow-color !important;
}

.btn-light {
 .btn-color(~"light");
 color:@btn-light-color !important;

 &.btn-xs:after {
	left: -2px;
	right: -2px;
	top: -2px;
	bottom: -2px;
 }
 &.btn-sm:after {
	left: -4px;
	right: -4px;
	top: -4px;
	bottom: -4px;
 }
 .btn-lg:after {
	left: -6px;
	right: -6px;
	top: -6px;
	bottom: -6px;
 }
}

}
.enable_default_buttons();



.enable_white_buttons() when(@enable-white-buttons = true) {
.btn.btn-white {
	//border-width: 1px;
	text-shadow: none !important;
	background-color: #FFF !important;

	&.no-hover:hover , &.no-hover:active {
		background-color: #FFF !important;
	}

	&:focus , &.active {
		box-shadow: inset 1px 1px 2px 0 rgba(0,0,0,0.1) !important;

		&.btn-bold {
			box-shadow: inset 1px 1px 3px 0 rgba(0,0,0,0.15) !important;
		}
	}
	&.active:after {
		display: none;
	}
}
.btn-white(@txt-color, @border-color, @bg-hover-color) {
 border-color: @border-color;
 color: @txt-color !important;

 &:hover, &:focus , &.active, &:active, .open &.dropdown-toggle {
	background-color: @bg-hover-color !important;
	border-color: @border-color;
 }
 &:hover {
	color: saturate(darken(@txt-color , 5%) , 5%) !important;
 }
 &.no-border:hover , &.no-border:active {
	border-color: @border-color;
 }

 &.disabled, &[disabled], fieldset[disabled] & {
	&,
    &:hover,
    &:focus,
    &:active,
    &.active {
		//background-color: #FFF !important;
		border-color: @border-color;
	}
  }
}

.btn.btn-white {
  .btn-white(#444, #CCC , #EBEBEB);
}
.btn-white.btn-default {
  .btn-white(darken(@btn-default-hover, 4%), @btn-default , lighten(@btn-default, 23%));
}
.btn-white.btn-primary {
  .btn-white(desaturate(@btn-primary , 30%), desaturate(lighten(@btn-primary , 15%), 15%) , desaturate(lighten(@btn-primary , 42%), 6%));
}
.btn-white.btn-success {
  .btn-white(desaturate(darken(@btn-success , 4%) , 8%), desaturate(lighten(@btn-success , 10%), 2%) , desaturate(lighten(@btn-success , 33%), 1%));
}
.btn-white.btn-danger {
  .btn-white(desaturate(lighten(@btn-danger, 5%) , 30%), desaturate(lighten(@btn-danger , 18%), 18%) , desaturate(lighten(@btn-danger , 42%), 7%));
}
.btn-white.btn-warning {
  .btn-white(desaturate(darken(@btn-warning , 6%), 36%), desaturate(lighten(@btn-warning , 3%), 30%) , desaturate(lighten(@btn-warning , 30%), 10%));
}
.btn-white.btn-info {
  .btn-white(desaturate(darken(@btn-info, 6%) , 25%), desaturate(lighten(@btn-info , 5%), 15%) , desaturate(lighten(@btn-info , 30%), 6%));
}
.btn-white.btn-inverse {
  .btn-white(@btn-inverse, lighten(@btn-inverse , 25%) , lighten(@btn-inverse , 56%));
}
.btn-white.btn-pink {
  .btn-white(desaturate(@btn-pink , 35%), desaturate(lighten(@btn-pink , 15%), 25%) , desaturate(lighten(@btn-pink , 40%), 5%));
}
.btn-white.btn-purple {
  .btn-white(darken(desaturate(@btn-purple , 10%) , 10%), desaturate(lighten(@btn-purple , 10%), 15%) , desaturate(lighten(@btn-purple , 31%), 5%));
}
.btn-white.btn-yellow {
  .btn-white(darken(desaturate(@btn-yellow , 20%) , 30%), desaturate(darken(@btn-yellow, 5%), 25%) , desaturate(lighten(@btn-yellow , 18%), 10%));
}
.btn-white.btn-grey {
  .btn-white(darken(@btn-grey , 8%), lighten(@btn-grey , 15%) , lighten(@btn-grey , 30%));
}


}
.enable_white_buttons();







.btn.disabled, .btn[disabled] {
 &.active, &:focus, &:active {
	outline:none;
 }
 &:active {
	top:0; left:0;
 }
}

//active buttons
.btn.active {
 color:@btn-active-color;
 &:after {
	display: inline-block;
	content: "";
	position: absolute;
	border-bottom: 1px solid @btn-active-color;
	left: -4px;
	right: -4px;
	bottom: -4px;
 }
 &.btn-sm:after {
	left: -3px;
	right: -3px;
	bottom: -3px;
	//border-bottom-width:1px;
 }
 &.btn-lg:after {
	left: -5px;
	right: -5px;
	bottom: -5px;
	//border-bottom-width:1px;
 }
 &.btn-xs:after ,  &.btn-minier:after {
	left: -1px;
	right: -1px;
	bottom: -2px;
	//border-bottom-width:1px;
 }
 &.btn-minier:after {
	bottom: -1px;
 }

  &.btn-yellow:after {
	border-bottom-color: @btn-yellow-active-border;
 }
 &.btn-light {
	color: #515151;
	&:after {
		border-bottom-color: #B5B5B5;
	}
 }
}




//icons inside buttons
.btn {
	> .@{icon} {
		margin-right: 4px;
		//min-width: 12px;
		//display: inline;

		&.icon-on-right {
			margin-right: 0;
			margin-left: 4px;
		}
	}
	> .icon-only.@{icon} {
		margin: 0 !important;//to override .rtl
		text-align: center;
		padding: 0;
		//min-width: 24px;
		//vertical-align: middle;
	}
}

.btn-large > .@{icon} {
	margin-right: 6px;

	&.icon-on-right {
		margin-right: 0;
		margin-left: 6px;
	}
}
.btn-sm > .@{icon} {
	margin-right: 3px;

	&.icon-on-right {
		margin-right: 0;
		margin-left: 3px;
	}
}
.btn-xs > .@{icon} , &.btn-minier > .@{icon} {
	margin-right: 2px;

	&.icon-on-right {
		margin-right: 0;
		margin-left: 2px;
	}
}



.btn.btn-link {
  border: none !important;
  background: transparent none !important;
  color: @btn-link-color !important;
  text-shadow: none !important;
  padding: 4px 12px !important;
  line-height: 20px !important;

 &:hover {
	background: none !important;
	text-shadow: none !important;
 }
 &.active {
	background: none !important;
	text-decoration: underline;
	color: lighten(@btn-link-color , 6%) !important;
	&:after {
		display: none;
	}
 }
 &.disabled , &[disabled]{
	background: transparent none !important;
	.opacity(0.65);
	text-decoration: none !important;
 }
}

.btn.btn-no-border {
 border-width: 0 !important;
}




//button groups
.btn-group {
  &:first-child {
	margin-left: 0;
  }

  > .btn {
	  & , + .btn {
		 margin: 0 5px 0 0;
	  }
	  &:first-child {
		margin: 0 5px 0 0;
	  }
	  &:first-child , &:last-child {
		border-radius: 0;
	  }

	  //caret inside buttons
	  > .caret {
		 margin-top: 15px;
		 margin-left: 1px;
		 border-width: 1px;
		 border-top-color: #FFF;
	  }

	 &.btn-sm > .caret {
		margin-top: 10px;
		border-width: 1px;
	 }
	 &.btn-large > .caret {
		margin-top: 18px;
		border-width: 1px;
	 }
	 &.btn-xs > .caret {
		margin-top: 9px;
		border-width: 1px;
	 }
	 &.btn-minier > .caret {
		margin-top: 7px;
		border-width: 1px;
	 }

	 //dropdown toggle
	 + .btn.dropdown-toggle {
		padding-right: 3px;
		padding-left: 3px;
	}
	+ .btn-large.dropdown-toggle {
		padding-right: 4px;
		padding-left: 4px;
	}

  }
  .dropdown-toggle {
	border-radius: 0;
  }



  .btn-group-active-state(@left, @right, @bottom, @width) {/* the border under an active button in button groups */
		&.active:after {
			left: unit(@left, px); right:unit(@right, px); bottom:unit(@bottom, px);
			border-bottom-width: unit(@width, px);
		}
  }
   > .btn  , + .btn{
		margin: 0 5px 0 0;
		border-width: 1px;// !important;
		.btn-group-active-state(-2, -2, -2, 1);
   }

   > .btn-large  , + .btn-large{
		border-width: 1px;// !important;
		.btn-group-active-state(-3, -3, -3, 1);
   }
   > .btn-sm  , + .btn-sm{
		border-width: 1px;// !important;
		.btn-group-active-state(-1, -1, -1, 1);
   }
   > .btn-xs  , + .btn-xs{
		border-width: 1px;// !important;
		.btn-group-active-state(0, 0, 0, 1);
   }
   > .btn-minier  , + .btn-minier{
		border-width: 1px;// !important;
		.btn-group-active-state(0, 0, 0, 1);
   }
}

.btn-group > .btn.btn-round {
	border-radius: 3px !important;
}
.btn-group-vertical > .btn , .btn-group-vertical > .btn + .btn {
	margin: 1px 0 0 !important;//to override .RTL's
}
.btn-group-vertical > .btn:first-child {
	margin-top: 0 !important;//to override .RTL's
}
.btn-group.btn-overlap > .btn {
	margin-right: -1px;
}
.btn-group.btn-corner > .btn {
	&:first-child {
		border-bottom-left-radius: 8px !important;
		border-top-left-radius: 8px !important;
	}
	&:last-child {
		border-bottom-right-radius: 8px !important;
		border-top-right-radius: 8px !important;
	}
	&.btn-sm:first-child {
		border-bottom-left-radius: 6px !important;
		border-top-left-radius: 6px !important;
	}
	&.btn-sm:last-child {
		border-bottom-right-radius: 6px !important;
		border-top-right-radius: 6px !important;
	}
	&.btn-xs:first-child {
		border-bottom-left-radius: 4px !important;
		border-top-left-radius: 4px !important;
	}
	&.btn-xs:last-child {
		border-bottom-right-radius: 4px !important;
		border-top-right-radius: 4px !important;
	}
}



.btn.btn-white {
  border-width: 1px;
}
.btn.btn-bold {
  border-bottom-width: 2px;
}
.btn.btn-round {
  border-bottom-width: 2px;
  border-radius: 4px !important;
}



//application buttons
.enable_application_buttons() when(@enable-application-buttons = true) {
.btn.btn-app {
 display: inline-block;
 width: 100px;

 font-size: @font-size-btn-app;
 font-weight: normal;
 color: #FFF;

 text-align: center;

 border: none;
 border-radius: 12px;

 padding: 12px 0 8px;
 margin: 2px;

 line-height: 1.7;

 position: relative;
}


//button color
.btn-app-color(@color1, @color2, @percent) {
  & , &.no-hover:hover , &.disabled:hover {
	background: average(@color1, @color2) !important;
	#gradient > .vertical(@color1 , @color2) !important;
  }
  &:hover {
	background: average(darken(@color1,@percent), darken(@color2,@percent)) !important;
	#gradient > .vertical(darken(@color1,@percent) , darken(@color2,@percent)) !important;
  }
}

.btn-app-color(@color-name, @percent:10%) {
 @color1-name : ~`"btn-app-@{color-name}-1"`;
 @color2-name : ~`"btn-app-@{color-name}-2"`;

 .btn-app-color(@@color1-name, @@color2-name , @percent);
}

.btn-app, .btn-app.btn-default {
	.btn-app-color(~"default" , 8%);
}
.btn-app.btn-primary {
	.btn-app-color(~"primary");
}
.btn-app.btn-info {
	.btn-app-color(~"info");
}
.btn-app.btn-success {
	.btn-app-color(~"success");
}
.btn-app.btn-danger {
	.btn-app-color(~"danger");
}
.btn-app.btn-warning {
	.btn-app-color(~"warning");
}
.btn-app.btn-purple {
	.btn-app-color(~"purple");
}
.btn-app.btn-pink {
	.btn-app-color(~"pink");
}
.btn-app.btn-inverse {
	.btn-app-color(~"inverse");
}
.btn-app.btn-grey {
	.btn-app-color(~"grey" , 5%);
}

.btn.btn-app.btn-light {
 .btn-app-color(~"light" , 5%);

 color: @btn-app-light-color !important;
}

.btn.btn-app.btn-yellow {
 .btn-app-color(~"yellow" , 5%);

 color:@btn-app-yellow-color !important;
}


.btn.btn-app {
	&.btn-sm {
		width: 80px;
		font-size: @font-size-btn-app-sm;
		border-radius: 10px;

		line-height: 1.5;
	}
	&.btn-xs {
		width: 64px;
		font-size: @font-size-btn-app-xs;
		border-radius: 8px;
		padding-bottom: 7px;
		padding-top: 8px;

		line-height: 1.45;
	}



   > .@{icon} {
		display: block;
		font-size: @font-size-btn-app-icon;

		margin: 0 0 4px;
		line-height: 36px;
		min-width: 0;

		padding: 0;
   }
   &.btn-sm > .@{icon} {
		display: block;
		font-size: @font-size-btn-app-sm-icon;
		line-height: 30px;
		margin: 0 0 3px;
	}
   &.btn-xs > .@{icon} {
		display: block;
		font-size: @font-size-btn-app-xs-icon;
		line-height: 24px;
		margin: 0;
	}

	&.no-radius {
		border-radius: 0;
	}
	&.radius-4 {
		border-radius: 4px;
	}

	//badge & label inside buttons
	> .badge , > .label {
		position: absolute !important;
		top: -2px;
		right: -2px;
		padding: 1px 3px;
		text-align: center;
		font-size: @font-size-label-inside-btn-app - 1;

		color: #FFF;

		&.badge-left , &.label-left{
			right: auto;
			left: -2px;
		}
	}
	> .label {
		padding:1px 6px 3px;
		font-size: @font-size-label-inside-btn-app;
	}
	&.radius-4 , &.no-radius {
		> .badge {
			border-radius:3px;
			&.no-radius {// > .badge.no-radius
				border-radius:0;
			}
		}
	}

	/* active state */
	&.active {
		color:@btn-app-active;
		&:after {
			display:none;
		}
		&.btn-yellow {
			color:@btn-app-yellow-color;
			border-color:@btn-app-yellow-border;
		}
		&.btn-light {
			color:@btn-app-light-active;
		}
	}
}


.btn.btn-app {
 &.btn-light, &.btn-yellow {
	.box-shadow(~"0 0 0 1px rgba(0, 0, 0, 0.08) inset") !important;
 }
}


}
.enable_application_buttons();
