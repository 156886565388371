@import (reference) "./ace.less";






//side menu styling

//some sidebar variables
@sidebar-background: #F7F7F7;
@sidebar-border: #CCC;


@nav-item-color: #444;
@nav-item-background: #F8F8F8;
@nav-item-border: #E5E5E5;

@nav-item-color-hover: @theme-color-main-hover;
@nav-item-background-hover: #FFF;

@nav-item-background-open: #FAFAFA;

@nav-item-color-active: @theme-color-main-hover;//#0B6CBC;
@nav-item-background-active: #FFF;

@nav-item-color-focus: #ee7d26;


@nav-item-icon-width: 30px;



@nav-item-hover-indicator: @theme-color-main-hover;
@nav-item-arrow-color: #777;
@nav-item-arrow-color-active: @theme-color-main-hover;


@submenu-border: @nav-item-border;
@submenu-background: #FFF;
@submenu-item-background: #FFF;
@submenu-item-color: #616161;
@submenu-item-border: #E4E4E4;
@submenu-item-icon-size: 18px;

@submenu-item-color-hover: @theme-color-main-hover;
@submenu-item-background-hover: #F9F9F9;
@submenu-item-icon-active: #C86139;
@submenu-item-background-active: ##FCFCFC;
@submenu-border-active: #83B6D1;


@3rd-level-item-background-hover: @submenu-item-background-hover;
@3rd-level-item-color-hover: @submenu-item-color-hover;

@submenu-tree-line-style: dotted;
@submenu-tree-line-color: mix(#BCCFE0 , #7EAACB);
@submenu-tree-line-color-active: mix(mix(#BCCFE0 , #7EAACB) , #7EAACB);


@menumin-text-background: #F5F5F5;
@menumin-border: #CCC;
@menumin-shadow1:~"2px 1px 2px 0 rgba(0,0,0,0.1)";//!ignore
@menumin-shadow2:~"2px 2px 2px 0 rgba(0,0,0,0.1)";//!ignore


@sidebar-toggle-background: #F5F5F5;
@sidebar-toggle-border: #E0E0E0;
@sidebar-toggle-icon-color: #AAA;
@sidebar-toggle-icon-border: #BBB;
@sidebar-toggle-icon-background: #FFF;

@shortcuts-background: #FAFAFA;
@shortcuts-border: #DDD;

@hover-submenu-border: @menumin-border;


@zindex-sidebar-fixed: @zindex-navbar-fixed - 3;
@zindex-breadcrumbs-fixed: @zindex-sidebar-fixed - 3;//so that .sidebar-shortcuts appears above breadcrumbs.
@zindex-submenu: @zindex-sidebar-fixed - 1;



.sidebar.sidebar-fixed {
 width: @sidebar-width;
 z-index:998 !important;
 float: left;
 position: static;
 //why not simply position:absolute?
 //because we need the page height to be at least as high as the sidebar in case the page content is too small

 padding-left: 0;//override .nav-collapse
 padding-right: 0;


 //the grey background of sidebar
 &:before {
	 content: "";
	 display: block;
	 width: inherit;

	 position: absolute;//the relative parent is "html" element
	 top: 0;
	 bottom: 0;
	 z-index: -1;

	 background-color: inherit;
	 border-style: inherit;
	 border-color: inherit;
	 border-width: inherit;
 }

}




//side navigation
/**li .@{icon} {
 & , .nav-list & {
	width: auto;
 }
}*/

.nav-list {
  margin: 0;
  padding: 0;
  list-style: none;

  > li , > li > a , .nav-header {
	margin: 0;
  }
  li > a:focus {
	outline: none;
  }
}

.nav-list > li {
  display: block;
  position: relative;
  float: none;

  padding: 0;
  border-style: solid;
  border-width: 1px 0 0;

  &:last-child {
	border-bottom-width: 1px;
  }

  &.active > a {
	font-weight: bold;
  }

 /////////
 > a {
	 display: block;
	 height: @nav-item-height;
	 line-height: floor(@nav-item-height / 2) - 2;

	 padding-left: 7px;

	 text-shadow: none !important;

	 font-size: @nav-item-font-size;
	 text-decoration: none;

	 > .menu-icon {
		display: inline-block;
		min-width: @nav-item-icon-width;
		margin-right: 2px;

		vertical-align: sub;

		text-align: center;
		font-size: @nav-item-icon-size;
		font-weight: normal;
	 }
 }


 //the blue hover border on left
 &:before {
	display: none;
	content: "";
	position: absolute;
	top: -1px;
	left: 0;
	z-index: 1;
	height: @nav-item-height + 2;

	width: 3px;
	max-width: 3px;

	overflow: hidden;

	background-color: @nav-item-hover-indicator;

	@media only screen and (min-width: @grid-float-breakpoint) {
		.main-container.container .sidebar:not(.h-sidebar) & {
			//left: -3px;
			left: -2px;
		}
	}
 }
 &:hover:before {
	display: block;
 }


 // the submenu indicator arrow
 a > .arrow {
	display: block;

	width: 14px !important;
	height: 14px;
	line-height: 14px;

	text-shadow: none;
	font-size: @nav-item-arrow-size;


	position: absolute;
	right: 14px;
	top: floor(@nav-item-height / 3) - 1;

	padding: 0;
	text-align: center;
 }

 &.separator {
	height: 3px;
	background-color: transparent;
	position: static;
	margin: 1px 0;

	.box-shadow(none);
 }


 //submenu
 &.active .submenu {
	display:block;
 }
 .submenu {
	display: none;
	list-style: none;
	margin: 0;
	padding: 0;
	line-height: 1.5;

	position: relative;

   > li {
	 margin-left: 0;
	 position: relative;

	 > a {
		 display: block;
		 position: relative;
		 padding: 7px 0 9px 37px;
		 margin: 0;

		 border-top-width: 1px;
		 border-top-style: dotted;

		 &:hover {
			text-decoration: none;
		}
	 }

	//optional icon before each item
	a > .menu-icon {
		display: none;

		font-size: @font-size-submenu-item-icon;
		font-weight: normal;

		width: @submenu-item-icon-size;
		height: auto;
		line-height: 12px;
		text-align: center;

		position: absolute;
		left: 10px;
		top: 11px;
		z-index: 1;

		background-color: inherit;
	}
	&.active, &:hover {
		> a > .menu-icon {
			//show the icon on hover or when active
			display: inline-block;
		}
	}
  }// > li
 }//end of submenu


 //first level submenu
 > .submenu {
	border-top: 1px solid;

	> li {
		//tree like menu
		&:before {
			//the horizontal line
			content: "";
			display: block;
			width: 7px;

			position: absolute;
			z-index: 1;
			left: 20px;
			top: 17px;

			border: 1px @submenu-tree-line-style;
			border-width: 1px 0 0;
		}
	}

	//the vertical tree line
	&:before {
		content: "";
		display: block;
		position: absolute;
		z-index: 1;
		left: 18px;
		top: 0;
		bottom: 0;

		border: 1px @submenu-tree-line-style;
		border-width: 0 0 0 1px;
	}
 }

}//end of .nav-list > li



.nav-list li {
 &:hover > a > .arrow , &.active > a > .arrow , &.open > a > .arrow {
	color: inherit;
 }
}


//override opera only
no_such_element:-o-prefocus, .nav-list > li > a > .menu-icon {
  vertical-align: middle;
}



.3rd_level_icon() {
	display: inline-block;
	//font-size: @font-size-submenu-3rd-level-icon;
	//color: inherit;

	width: auto;
	position: static;
	background-color: transparent;
	margin-right: 4px;
}

//3rd & 4th level menu
.nav-list > li > .submenu {
  li > .submenu {
	display: none;
  }
  li.active > .submenu {
	display: block;
  }

  a > .arrow {
	right: 10px;
	top: 10px;
	font-size: floor(@nav-item-arrow-size * 0.9);
  }

  > li:first-child > a {
	border-top-width: 0;
  }


  li > .submenu > li {
		line-height: 18px;
		&:before {
			//hide the tree like menu
			display: none;
		}

		> a {
			//3rd level
			margin-left: 20px;
			padding-left: 22px;
		}
		> .submenu > li > a {
			//4th level
			margin-left: 20px;
			padding-left: 38px;
		}

		a > .menu-icon {
			.3rd_level_icon();
			color: inherit;
		}

		a {
			//font-size: @baseFontSize;
			.menu-icon , .arrow {
				color: inherit;
			}
		}

  }

  li.open:not(.hover) > a {
	> .menu-icon {
		display: inline-block;
	}
  }
}//.nav-list > li > .submenu





//labels and badges inside nav item
.nav-list a {
 .badge , .label {
	position: absolute;
	top: 9px;
	right: 10px;

	opacity: 0.88;
	font-size: @baseFontSize - 1;

	padding-left: 6px;
	padding-right: 6px;

	.@{icon} {
		vertical-align: middle;
		margin: 0;
	}
 }
 &.dropdown-toggle {
	.badge , .label {
		right: 26px;//move lefter because of .arrow
	}
 }
}
.nav-list li:hover > a {
	.badge , .label {
		opacity: 1;
	}
}


.nav-list .submenu .submenu a {
 .badge , .label {
	top: 6px;
 }
}






//shortcut buttons and sidebar toggle
@import "sidebar/sidebar-fixed.less";

//shortcut buttons and sidebar toggle
@import "sidebar/shortcuts-toggle.less";

//sidebar active state arrows , etc
@import "sidebar/active.less";

//submenu compact
@import "sidebar/compact.less";

//submenu hover style
@import "sidebar/hover.less";
@import "sidebar/sub-arrow.less";

//minimized sidebar mode
@import "sidebar/min.less";

//hover highlight
@import "sidebar/highlight.less";

//horizontal sidebar style
@import "sidebar/horizontal.less";


//default sidebar color
//you can import a different skin
@import "skins/no-skin.less";



//sidebar responsive styles
@import "sidebar/responsive-1.less";//default
@import "sidebar/old-toggle-button.less";//old style toggle button

@import "sidebar/responsive-2.less";//like minimized sidebar
@import "sidebar/responsive-3.less";//bootstrap style





.sidebar-scroll {
 .sidebar-shortcuts {
	min-height: @breadcrumb-height;
	border-bottom: 1px solid;
 }
 .sidebar-toggle {
	border-top: 1px solid;
 }
 .nav-list > li:first-child {
	border-top-width: 0;
 }
 .nav-list > li:last-child {
	border-bottom-width: 0;
 }



 .nav-wrap {
	.sidebar-shortcuts {
		min-height: @breadcrumb-height - 1;
		border-bottom-width: 0;

		+ .nav-list  > li:first-child {
			border-top-width: 1px;
		}
	}
 }
 .nav-wrap-t {
	.sidebar-toggle {
		border-top-width: 0;
	}
	.nav-list > li:last-child {
		border-bottom-width: 1px;
	}
 }
}

.sidebar {
 .nav-wrap + .ace-scroll {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
 }
 .nav-wrap + .scrollout {
	right: -8px;
 }
}
