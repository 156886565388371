@import (reference) "./ace.less";






.enable_profile() when(@enable-profile = true) {

.profile-user-info {
	display: table;
	width: 98%;
	width: ~"calc(100% - 24px)";
	margin: 0 auto;
}
.profile-info-row {
	display: table-row;
}
.profile-info-name {
	text-align: right;
	padding: 6px 10px 6px 4px;

	font-weight: normal;
	color: #667E99;
	background-color: transparent;

	border-top: 1px dotted #D5E4F1;
	
	display: table-cell;
	width: 110px;
	vertical-align: middle;
}
.profile-info-value {
	display: table-cell;

	padding: 6px 4px 6px 6px;
	//margin-left: 110px;
	border-top: 1px dotted #D5E4F1;
	
	> span  + span:before{/* for a list of values (such as location city & country) put a comma between them */
		display:inline;
		content:",";
		margin-left:1px;
		margin-right:3px;
		color:#666;
		border-bottom:1px solid #FFF;
	}
	> span  + span.editable-container:before {
		display:none;
	}
}

.profile-info-row {
	&:first-child .profile-info-name {
		border-top:none;
	}
	&:first-child .profile-info-value {
		border-top:none;
	}
}


.profile-user-info-striped {
	border: 1px solid #DCEBF7;

	.profile-info-name {
		color: #336199;
		background-color: #EDF3F4;
		border-top: 1px solid #F7FBFF;
	}
	.profile-info-value {
		border-top: 1px dotted #DCEBF7;
		padding-left: 12px;
	}
}




.profile-picture {
	border: 1px solid #e8e8e8;
	background-color: #FFF;
	padding: 4px;
	display: inline-block;
	max-width: 100%;
	
	.box-sizing(border-box);
	box-shadow:1px 1px 1px rgba(0,0,0,0.15);
}


.profile-activity {
	padding:10px 4px;
	border-bottom:1px dotted #D0D8E0;
	position:relative;
	border-left:1px dotted #FFF;
	border-right:1px dotted #FFF;
	&:first-child {
		border-top:1px dotted transparent;
		&:hover {
			border-top-color:#D0D8E0;
		}
	}
	
	&:hover {
		background-color:#F4F9FD;
		border-left:1px dotted #D0D8E0;
		border-right:1px dotted #D0D8E0;
	}
	
	
	img {
		border:2px solid #C9D6E5;
		border-radius:100%;
		max-width:40px;
		margin-right:10px;
		margin-left:0px;
		box-shadow:none;
	}
	.thumbicon {
		background-color: #74ABD7;
		display: inline-block;
		border-radius: 100%;
		
		width: 38px;
		height: 38px;

		color: #FFF;
		font-size: 18px;
		text-align: center;
		line-height: 38px;
		 
		margin-right:10px; margin-left:0px;
		text-shadow:none !important;
	}

	.time {
		display:block;
		margin-top:4px;
		color:#777;
	}

	a.user {
		font-weight:bold;
		color:#9585BF;
	}

	.tools {
		position:absolute;
		right: 12px;
		bottom:8px;
		display:none;
	}
	&:hover .tools {
		display:block;
	}
}






.user-profile .ace-thumbnails li {
 border:1px solid #e8e8e8;
 padding:3px;
 margin:6px;

 .tools {
	left:3px; right:3px;
  }
  &:hover .tools {
	bottom:3px;
  }
}



.user-profile .user-title-label {
	&:hover {
		text-decoration:none;
	}
	+ .dropdown-menu {
		margin-left:-12px;
	}
}


.profile-contact-links {
	padding: 4px 2px 5px;
	border: 1px solid #E0E2E5;
	background-color: #F8FAFC;
}
.btn-link:hover .@{icon} {
	text-decoration: none !important;
}
.profile-social-links > a {
	text-decoration: none;
	margin: 0 1px;
	&:hover > .@{icon} {
		text-decoration: none;
	}
}



.profile-skills .progress {
	height: 26px;
	margin-bottom: 2px;
	background-color:transparent;
	.progress-bar {
		line-height: 26px;
		font-size: @baseFontSize;
		font-weight: bold;
		font-family: "Open Sans";
		padding :0 8px;
	}
}



.profile-users {
	.user {
		display:block;
		position:static;
		text-align:center;
		width:auto;
		
		img {
			padding:2px;
			.border-radius(100%);
			border:1px solid #AAA;

			max-width:none;
			width:64px;
			
			.transition(~"all 0.1s");
			
			&:hover {
				.box-shadow(~"0 0 1px 1px rgba(0,0,0,0.33)");
			}
		}
	}

	.memberdiv {
		background-color:#FFF;
		width:100px;

		.box-sizing(border-box);
		border:none;
		text-align:center;

		margin:0 8px 24px;
		
		.name a:hover .@{icon} {
			text-decoration:none;
		}
		
		.body {
			display:inline-block;
			margin:8px 0 0 0;
		}

		.popover {
			visibility:hidden;
			min-width: 0;
			max-height: 0;
			max-width: 0;
		 
			margin-left:0;
			margin-right:0;

			top:-5%;
			left:auto;
			right:auto;
			
			opacity:0;
			display:none;
			position: absolute;

			.transition(~"opacity 0.2s linear 0s, visibility 0s linear 0.2s, max-height 0s linear 0.2s, max-width 0s linear 0.2s, min-width 0s linear 0.2s");
			
			&.right {
				left:100%;
				right:auto;
				display:block;
			}
			&.left {
				left:auto;
				right:100%;
				display:block;
			}
		}
		> :first-child:hover .popover {
			visibility:visible;
			opacity:1;
			z-index: @zindex-popover;
			max-height: 250px;
			max-width: 250px;
			min-width: 150px;

			-webkit-transition-delay:0s;
			-moz-transition-delay:0s;
			-o-transition-delay:0s;
			transition-delay:0s;
		}

		.tools {
			position:static;
			display:block;
			width:100%;
			margin-top:2px;
			> a {
				margin:0 2px;
				&:hover {
					text-decoration:none;
				}
			}
		}

	}
	
}




.user-status {
	display:inline-block;
	width:11px; height:11px;
	background-color:#FFF;
	border:3px solid #AAA;
	.border-radius(100%);
	vertical-align:middle;
	margin-right:1px;
	
	&.status-online{
		border-color:#8AC16C;
	}
	&.status-busy{
		border-color:#E07F69;
	}
	&.status-idle{
		border-color:#FFB752;
	}
}




.tab-content.profile-edit-tab-content {
	border:1px solid #E8E8E8;
	padding:8px 32px 32px;
	.box-shadow(~"1px 1px 0 0 rgba(0, 0, 0, 0.2)");
	
	background-color: #FFF;
}







//profile
@media only screen and (max-width: @screen-xs) {
 .profile-info-name {
	width: 80px;
 }

 .profile-user-info-striped {
	.profile-info-name {
		float: none;
		width: auto;
		text-align: left;
		padding: 6px 4px 6px 10px;
		display: block;
	}
	.profile-info-value {
		margin-left: 10px;
		display: block;
	}
 }
}

@media only screen and (max-width: @screen-xs) {
 .user-profile .memberdiv {
	width: 50%;
	margin-left: 0;
	margin-right: 0;
 }
}





}
.enable_profile();
