.buttonrow{
  margin-bottom:20px;
  margin-top:10px;
}

.bgtable{
	background:#E1E1E1;
	padding:10px;
	border:1px solid white;

}

.table{
	background:white;
}
.leftbtn{
	left:-15px!important;
}
.rightbtn{
	right:-15px!important;
}
.liblue{
	background:#E1E1E1;
	padding:10px;

}
/*.boxgrey{
	border:1px solid white;
	background:lightgrey;
	padding-left: 15px;
	border-top:none;
}*/

.sorting_1 {
  background-color:#F5F5F5 !important;
}

.hide_pay {
  padding-top:17px !important;
}

.arenaplan{
	margin-top:30px;
}
.lbl{
	top: -2px!important;
	position:relative;
	padding-right:15px;
}
.showsteh{
	display:none;
}
.libo1, .libo2{
	border:30px solid white;
	border-radius:4px;
	position:fixed;
	top:20%;
	left:11%;
	width:78%;
	z-index:1000000;
	padding:0 0px 0 0px;
	display:none;
	min-width:850px;
	background:white;
}

.libocon3{
    z-index: 99999999
}
html body div.libo1 .liblue, .libo2 .liblue, .customer_libo .ligrey, .customer_res_libo .ligrey,.customer_res_list_libo  .ligrey , html body div.libo1 .ligrey, .libo2 .ligrey,  .customer_libo  .liblue, .customer_res_libo  .liblue, .customer_res_list_libo  .liblue{
  max-width:  96%;
}
.liboclosex>i{
	font-size:18px;
	font-weight:bold;
	font-style:normal;
	line-height:18px;
	margin:0px;

}
.bold{
	font-weight:bold;
	font-size:17px;
}


.sbutt{
    height: 17px;
    left: 53px;
    position: absolute;
    width: 17px !important;


}
.sbutt>i{
    height: 17px;
    left: 5px;
    position: absolute;
    top: 4px;
    width: auto !important;
    z-index: 9999

}
.sbutt:hover{
	display:none!important;
}

.ace-spinner{
	width:70px!important;
}
.zoom-in, .zoom-out, .reset{
    border: medium none;
    height: 50px;
    line-height: 50px;
    margin-left: -11px;
    margin-right: -11px;
    width: 50px;
    background:none;
}
.zoom-in{
  line-height: 21px;
  font-size:30px;
}

.zoom-out{
  line-height: 64px;
  font-size:25px;
}

.reset{
  line-height: 64px;
  font-size:20px;
}
@media screen and (min-width:1224px){
	.panzoom-view-port {
		/*left: -20% !important;*/
		position: relative;
		/*top: -165px;*/
		/*width: 140% !important;*/
		-khtml-user-select: none;
		-moz-user-select:none;
		-webkit-user-select: none;
		-ms-user-select: none;
	}
	.panzoom-buttons {
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    z-index: 99;
    padding: 0px 15px 0 0;
   /* border-radius: 0px 0px 5px 0px;*/

		top:0!important;
	}

}

@media screen and (max-width:1224px){
	.panzoom-buttons {
	  top:30px;

	}
}
.panzoom-buttons:hover {
  z-index: 190;
}

.arenaplan >.container{
  max-width:100%;
}
.glyphicon-edit, .fa-times{
  cursor:pointer;
}

.liboclosex > i {
    margin-right: 0 !important;
    cursor:pointer;
}
.liboclose > i, .libosteh > i, .libositz > i, .liboclose {
    cursor:pointer;
}
.customer_libo, .customer_res_libo {
  max-width:96%;
  min-height:200px;
  border:30px solid white;
	border-radius:4px;
	position:fixed;
	top:11%;
	left:21%;
	width:50%;
	z-index:10000000;
	padding:0 0px 0 0px;
	display:none;
	min-width:850px;
	background:white;
  cursor:normal;
}
.customer_libo{
    z-index: 100000000;
}
.customer_res_list_libo{
  max-width:96%;
  min-height:200px;
  max-height:500px;
  overflow-y:scroll;
  overflow-x: hidden;
  border:30px solid white;
	border-radius:4px;
	position:fixed;
	top:11%;
	left:11%;
	width:78%;
	z-index:10000000;
	padding:0 0px 0 0px;
	display:none;
	min-width:850px;
	background:white;
  cursor:normal;
}

.customer_libo_butt{
  vertical-align:middle;
}
.martop5{
    margin-top:5px;
}

.boxoffice-cart-details {
    border: none;
    background-color: #EEEEEE;
}

#boxoffice_cart_table thead, #boxoffice_cart_table tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}

#boxoffice_cart_table tbody {
    display:block;
    height:150px;
    overflow:auto;
}

#boxoffice_cart_table thead {
}

#boxoffice_cart_table tfoot {
    display:table;
    width:100%;
    table-layout:fixed;
}

.inline-block {
  display:inline-block;
  padding-right:15px;
}

.boxoffice_table_concat {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.boxoffice_table_column_1 {
    width: 12%
}

.boxoffice_table_column_2 {
    width: 24%
}

.boxoffice_table_column_3 {
    width: 12%
}

.boxoffice_table_column_4 {
    width: 7%
}

.boxoffice_table_column_5 {
    width: 20%
}

.boxoffice_table_column_6 {
    width: 10%
}

.boxoffice_table_column_7 {
    width: 10%
}

.boxoffice_table_column_8 {
    width: 5%
}

.table-responsive {
    margin-bottom: 0px
}

.boxoffice_table_line {
  line-height: 15px
}


table > thead > tr {
  background-image:none;
}

 .dataTable > thead > tr > th.sorting_desc, .dataTable > thead > tr > th.sorting_asc {
    background-color: #eae9e9;
    color:#555;
}

.btn-default:not( .btn-primary ):not( .btn-white ) {
  border-color: #DDD !important;
  color: #333 !important;
}
