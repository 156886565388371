@import (reference) "./ace.less";






//some searchbox variables
@search-border: #6FB3E0;
@search-color: #666;
@search-color-focus: #656A72;

@search-width: 152px;
@sb-search-width: 162px;
@search-border-radius: 4px;

@nav-search-height: 24px;


.enable_search_box() when(@enable-search-box = true) {

// searchbox
.nav-search {
  position: absolute;
  right: 22px;
  line-height: @nav-search-height;

  .breadcrumbs & {
	  top: floor((@breadcrumb-height - @nav-search-height) / 2) - 2;
  }

  .form-search {
	margin-bottom: 0;
  }

 .nav-search-input {
	border: 1px solid @search-border;

	width: @search-width;
	height: 28px !important;
	padding-top: 2px;
	padding-bottom: 2px;
	
	border-radius: @search-border-radius !important;

	font-size: @font-size-searchbox;
	line-height: 1.3;
	
	color: @search-color !important;
	z-index: 11;

	.transition(~"width ease .15s");

	//the typeahead dropdown menu
	& + .dropdown-menu {
		min-width: 0;
		left: 0;
		right: 0;
	}

	&:focus , &:hover{
		border-color: @search-border;
	}

 }


 .nav-search-icon {
	color: @search-border !important;
	font-size: @font-size-searchbox-icon !important;
	line-height: 24px !important;
	background-color: transparent;
 }

 &.minimized {
	.nav_search_minimized()
 }
}



.nav_search_minimized() {
	.nav-search-input {
		width: 0;
		.opacity(0);
		max-width: 0; // for safari only
	}
	 
	&:hover .nav-search-input ,
	.nav-search-btn:active + .nav-search-input ,
	.nav-search-input:focus, .nav-search-input:hover, .nav-search-input:active
	{
		.opacity(1);
		width: @search-width;
		max-width: @search-width; // for safari only
	}

	.nav-search-icon {
		border: 1px solid;
		border-radius: 100%;

		background-color: #FFF;

		padding: 0 5px !important;
	}
	
	&:hover .nav-search-icon ,
	.nav-search-input:focus ~ .nav-search-icon,
	.nav-search-input:hover ~ .nav-search-icon,
	.nav-search-input:active ~ .nav-search-icon
	{
		border:none;
		border-radius:0;
		padding:0 3px !important;
	}
}


.nav_search_icon_active() {
	border:none;
	border-radius:0;
	padding:0 3px !important;
}

.nav-search-icon {
	.nav_search_icon_active();
}

//nav-search inside sidebar
.sidebar > .nav-search  {
   position:static;

   background-color:#FAFAFA;
   border-bottom:1px solid #E8E8E8;
   text-align:center;

   height:35px;
   padding-top:6px;


   .nav-search-input {
	 width:@sb-search-width !important;
	 border-radius:0 !important;
	 max-width:@sb-search-width !important;
	 .opacity(1) !important;
	 
	 & + .dropdown-menu { 
		text-align:left; 
	 }
   }

}//nav-search inside sidebar

  
//sidebar when minimized
.searchbox_inside_min_menu() {
  .nav-search {
	.form-search {
		position:absolute; left:5px;
		z-index:14;
	}
	
	.nav_search_input_active() {
		width:@sb-search-width !important;
		max-width:@sb-search-width !important;
		.opacity(1) !important;
	}
	.nav-search-input {
		width:0 !important;
		max-width:0 !important;
		.opacity(0) !important;
		
		&:hover, &:focus, &:active {
			.nav_search_input_active();
			 ~ #nav-search-icon {
				.nav_search_icon_active();
			 }
		}
	}
	
	&:hover .nav-search-input {
		.nav_search_input_active();
		~ .nav-search-icon {
			.nav_search_icon_active();
		}
	}
	
	.nav-search-icon {/* inside minimized sidebar */
		border:1px solid;
		border-radius:32px;

		background-color:#FFF;
		padding:0 5px !important;
	}
  }
}

	
	
	.enable_searchbox_menumin() when(@enable-sidebar-collapse = true) {
	 .sidebar.menu-min {
		.searchbox_inside_min_menu();
	 }
	}
	.enable_searchbox_menumin();
	
	.enable_searchbox_minimized_responsive_menu() when(@enable-minimized-responsive-menu = true) {
	  @media (max-width: @grid-float-breakpoint-max) {
	     .sidebar.responsive-min {
			.searchbox_inside_min_menu();
		 }
	  }
	}
	.enable_searchbox_minimized_responsive_menu();
	

	//responsive searchbox
	@media only screen and (max-width: @screen-sm-max) {
		.nav-search {
			right: 10px;
		}
		.nav-search .nav-search-input {
			width: 105px;
		}
		.nav-search:hover .nav-search-input ,
		.nav-search  .nav-search-btn:active + .nav-search-input ,
		.nav-search  .nav-search-input:focus, .nav-search  .nav-search-input:hover, .nav-search  .nav-search-input:active
		{
			width: 145px;
		}
	}
	@media only screen and (max-width: @screen-xs-max) {//@screen-xs
		.nav-search {
			.nav_search_minimized()
		}
	}

}
.enable_search_box();
