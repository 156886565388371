@import (reference) "./ace.less";






.enable_plugin_datetime() when(@enable-plugin-datetime = true) {
// date & time picker
.datepicker , .daterangepicker  {
	td , th { .border-radius(0) !important; font-size: @baseFontSize; }

	td.active {
	  & , &:hover {	background:@datepicker-active-bg !important; }

	  &.disabled {
		& , &:hover { background:@datepicker-disabled-bg !important; }
	  }
	}
}
.datepicker {
 td , th { min-width: 32px; }
}
.datepicker-dropdown.datepicker-orient-bottom {
  &:after, &:before {
	top: auto;
  }
}


.daterangepicker .calendar-date {
 border-radius: 0;
}

.datepicker-months .month , .datepicker-years .year {
  border-radius:0 !important;
}
.datepicker-months .month.active , .datepicker-years .year.active {
  & , &:hover , &:focus, &:active {
	  background-image: none !important;
	  background-color: @datepicker-active-bg !important;
  }
}
.bootstrap-timepicker-widget table td input {
	width: 32px;
	border:none;
}

.well .datepicker table tr td.day:hover {
	background-color: @datepicker-active-bg2;
	color: #FFF;
}

.bootstrap-timepicker-widget table td a:hover {
	.border-radius(0);
}


.daterangepicker.opensleft, .daterangepicker.opensright {
 &:before {
	-moz-border-bottom-colors: rgba(0, 0, 0, 0.2);
 }
 &:after {
	-moz-border-bottom-colors: #fff;
 }
}


.datepicker-dropdown:before {
 -moz-border-bottom-colors: rgba(0, 0, 0, 0.2);
}
.datepicker-dropdown:after {
 -moz-border-bottom-colors: #fff;
}
.datepicker-dropdown.datepicker-orient-bottom:before {
 -moz-border-top-colors: #999;
}
.datepicker-dropdown.datepicker-orient-bottom:after {
 -moz-border-top-colors: #FFF;
}
.bootstrap-timepicker-widget.dropdown-menu:before {
 -moz-border-bottom-colors: rgba(0,0,0,0.2);
}
.bootstrap-timepicker-widget.dropdown-menu:after {
 -moz-border-bottom-colors: #FFF;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
 -moz-border-top-colors: #999;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
 -moz-border-top-colors: #FFF;
}




//date time picker
.bootstrap-datetimepicker-widget [class=btn] {
	border-width: 0 !important;
	background-color: transparent !important;
	color: #777 !important;

	text-shadow: none !important;

	&:hover {
		color: #1B6AAA !important;
	}
}
.bootstrap-datetimepicker-widget .btn.btn-primary {
	border-width: 3px !important;
}

.bootstrap-datetimepicker-widget .picker-switch {
	margin-bottom: 2px;
	.btn {
		width: 90% !important;
		background-color: #EEE !important;
		color: @ace-blue !important;
		font-size: 16px;

		&:hover {
			background-color: desaturate(lighten(@btn-primary , 40%) , 10%) !important;
		}
	}
}
.bootstrap-datetimepicker-widget td span {
	border-radius: 0;
}
.bootstrap-datetimepicker-widget {
  .timepicker-hour, .timepicker-minute, .timepicker-second {
	color: #555 !important;
  }
}


}
.enable_plugin_datetime();
