//move the icons to the line below
@media only screen and (max-width: @screen-topbar-down) {
 .navbar:not(.navbar-collapse) {
	//.navbar-header.nav-brand , .navbar-header.navbar-buttons {
	.navbar-header {
		float: none !important;
		display: block;
	}

	.ace-nav {
		display: block;
		float: none !important;
		text-align: center;
		
		background-color: rgba(0,0,0,0.15);
		
		border: 1px solid rgba(0,0,0,0.1);//@ace-nav-border;
		border-width: 1px 0 0;

		letter-spacing: -3px;
		> li{
			display: inline-block;
			float:none !important;
			text-align: left;

			letter-spacing: normal;
			
			&:first-child {
				border-left: 1px solid @ace-nav-border;	
			}
			&:last-child {
				border-right: 1px solid @ace-nav-border;
			}
		}
	}
 }
}
@media only screen and (max-width: @screen-mini-max) {
 .ace-nav > li  > a {
	padding: 0 5px !important;
 }
}