.com { color: #93a1a1; }
.lit { color: #195f91; }
.pun, .opn, .clo { color: #93a1a1; }
.fun { color: #dc322f; }
.str, .atv { color: #D14; }
.kwd, .prettyprint .tag { color: #1e347b; }
.typ, .atn, .dec, .var { color: teal; }
.pln { color: #48484c; }

.prettyprint {
  padding: 8px;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
}
.prettyprint.linenums {
  -webkit-box-shadow: inset 40px 0 0 #fbfbfc, inset 41px 0 0 #ececf0;
          box-shadow: inset 40px 0 0 #fbfbfc, inset 41px 0 0 #ececf0;
}

/* Specify class=linenums on a pre to get line numbering */
ol.linenums {
  margin: 0 0 0 33px; /* IE indents via margin-left */
}
ol.linenums li {
  padding-left: 12px;
  color: #bebec5;
  line-height: 20px;
  text-shadow: 0 1px 0 #fff;
}


/*
 * Derived from einaros's Sons of Obsidian theme at
 * http://studiostyl.es/schemes/son-of-obsidian by
 * Alex Ford of CodeTunnel:
 * http://CodeTunnel.com/blog/post/71/google-code-prettify-obsidian-theme
 */
/**
.str
{
    color: #EC7600;
}
.kwd
{
    color: #93C763;
}
.com
{
    color: #66747B;
}
.typ
{
    color: #678CB1;
}
.lit
{
    color: #FACD22;
}
.pun
{
    color: #F1F2F3;
}
.pln
{
    color: #F1F2F3;
}
.tag
{
    color: #8AC763;
}
.atn
{
    color: #E0E2E4;
}
.atv
{
    color: #EC7600;
}
.dec
{
    color: purple;
}
pre.prettyprint
{
    border: 0px solid #888;
}
ol.linenums
{
    margin-top: 0;
    margin-bottom: 0;
}
.prettyprint {
    background: #000;
}
li.L0, li.L1, li.L2, li.L3, li.L4, li.L5, li.L6, li.L7, li.L8, li.L9
{
    color: #555;
    list-style-type: decimal;
}
li.L1, li.L3, li.L5, li.L7, li.L9 {
    background: #111;
}
@media print
{
    .str
    {
        color: #060;
    }
    .kwd
    {
        color: #006;
        font-weight: bold;
    }
    .com
    {
        color: #600;
        font-style: italic;
    }
    .typ
    {
        color: #404;
        font-weight: bold;
    }
    .lit
    {
        color: #044;
    }
    .pun
    {
        color: #440;
    }
    .pln
    {
        color: #000;
    }
    .tag
    {
        color: #006;
        font-weight: bold;
    }
    .atn
    {
        color: #404;
    }
    .atv
    {
        color: #060;
    }
}
*/