@import (reference) "./ace.less";






//jquery gritter
.enable_plugin_gritter() when(@enable-plugin-gritter = true) {

.gritter-item-wrapper {
	background-image:none !important;
	box-shadow:0 2px 10px rgba(50, 50, 50, 0.5);
	background:rgba(50,50,50,0.92);
	
	&.gritter-info {
		background:rgba(49, 81, 133, 0.92);
	}
	&.gritter-error {
		background:rgba(153, 40, 18, 0.92);
	}
	&.gritter-success {
		background:rgba(89, 131, 75, 0.92);
	}
	&.gritter-warning {
		background:rgba(190, 112, 31, 0.92);
	}
	
	&.gritter-light {
		background:rgba(245,245,245,0.95);
		border:1px solid #BBB;
		&.gritter-info {
			background:rgba(232, 242, 255, 0.95);
			.gritter-item { color:#4A577D; }
		}
		&.gritter-error {
			background:rgba(255, 235, 235, 0.95);
			.gritter-item { color:#894A38; }
		}
		&.gritter-success {
			background:rgba(239, 250, 227, 0.95);
			.gritter-item { color:#416131; }
		}
		&.gritter-warning {
			background:rgba(252, 248, 227, 0.95);
			.gritter-item { color:#946446; }
		}
	}
}

.gritter-item p {
	line-height:1.8;
}


.gritter-top , .gritter-bottom , .gritter-item {
	background-image:none;
}

.gritter-close {
	left: auto;
	right: 3px;
	
	background-image:none;

	width: 18px;
	height: 18px;
	line-height: 17px;
	
	text-align: center;
	border: 2px solid transparent;
	border-radius: 16px;

	color: #E17B67;
	
	&:before {
		font-family: FontAwesome;
		font-size: 16px;
		content: "\f00d";
	}
}


.gritter-info .gritter-close {
  color:#FFA500;
}
.gritter-error , .gritter-success , .gritter-warning {
  .gritter-close {
	color:#FFEA07;
  }
}
.gritter-close:hover{
 color:#FFF !important;
}


.gritter-title {
 text-shadow:none;
}

.gritter-light {
	.gritter-item , .gritter-bottom , .gritter-top , .gritter-close {
		background-image: none;
		color: #444;
	}
	.gritter-title {
		text-shadow: none;
	}
	.gritter-close:hover {
		color:#8A3104 !important;
	}
}

.gritter-center {
  position:fixed;
  left:33%; right:33%; top:33%;
}



@media only screen and (max-width: @screen-xs-max) {
.gritter-center {
  left:16%; right:16%; top:30%;
}
}
@media only screen and (max-width: @screen-xs) {
.gritter-center {
  left:30px; right:30px;
}
}
@media only screen and (max-width: @screen-tiny) {
.gritter-center {
  left:10px; right:10px;
}
}

}

.enable_plugin_gritter();
