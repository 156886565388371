@import (reference) "./ace.less";







//some elements variables
@blockquote-border:#e5e5e5;

@modal-footer-border:#e5e5e5;
@modal-footer-bg:#FCFCFC;

@pagination-color: #444;
@pagination-border: darken(#E0E8EB , 4%);
@pagination-bg: #F7F7F7;
@pagination-bg-hover: #FCFCFC;

    @pagination-bg-disabled: #F1F1F1;
@pagination-border-disabled: #D9D9D9;

 @pagination-color-active: @theme-color-main;
    @pagination-bg-active: #FCFCFC;
@pagination-border-active: #FCFCFC;




//elements
.@{icon} {
	text-align: center;
}
a {
 &:focus, &:active {
	text-decoration: none;
 }
}


//header sizes
.h-size(@index) when (@index > 0) {
	@h-tag : ~`"h@{index}"`;
	@{h-tag} {
		@tmpvar : ~`"h@{index}-size"`;//get the variable h1-size, h2-size , etc...
		font-size:unit(@@tmpvar , px);
		font-weight:normal;
		font-family:"Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;

		&.smaller {
			font-size: unit((@@tmpvar - 1) , px);
		}
		&.bigger {
			font-size: unit((@@tmpvar + 1) , px);
		}
		&.block {
			margin-bottom: 16px;
		}
	}
}
.h-size(1);
.h-size(2);
.h-size(3);
.h-size(4);
.h-size(5);
.h-size(6);




//some list styling
ul, ol {
 //split margins for better RTL side
 margin-top: 0;
 margin-bottom: 10px;
 margin-right: 0;
 margin-left: 25px;

 padding: 0;

 &.margin-5 { margin-left: 5px; }
 &.margin-10 { margin-left: 10px; }
 &.margin-15 { margin-left: 15px; }
 &.margin-20 { margin-left: 20px; }
}
li > ul,
li > ol {
 margin-left: 18px;
}

.list-unstyled , .list-inline {
 margin-left: 0;

 > li > .@{icon}:first-child {
	width: 18px;
	text-align: center;
 }
}

.spaced > li {
  margin-top: 9px;
  margin-bottom: 9px;
}
.spaced2 > li {
  margin-top: 15px;
  margin-bottom: 15px;
}
li.divider {
 margin-top: 3px;
 margin-bottom: 3px;
 height: 0;
 font-size: 0;

 .spaced > & {
	margin-top: 5px;
	margin-bottom: 5px;
 }
 .spaced2 > & {
	margin-top: 8px;
	margin-bottom: 8px;
 }

 &:before {
	content: "";
	display: inline-block;
 }
}



//little elements
blockquote , blockquote.pull-right, .blockquote-reverse {
	border-color: @blockquote-border;
}


pre.prettyprint {
	white-space: pre-wrap;
}


//modals
.modal-content {
 .box-shadow(none);
 overflow: hidden;
}
.modal-footer {
 padding-top: 12px;
 padding-bottom: 14px;

 border-top-color: @modal-footer-border;
 .box-shadow(none);
 background-color: @modal-footer-bg;
}
.modal-header .close {
 font-size: 32px;
}

.modal-header {
    background: #FFF;
}

//wells
.well {
}
.well h1, .well h2, .well h3, .well h4, .well h5, .well h6 {
 margin-top: 0;
}
.well h1, .well h2, .well h3 {
 line-height: 36px;
}


//alerts
.alert {
 margin-bottom:0;
 font-size: @font-size-alert;
 border-radius: 0;

 .close {
	font-size: 16px;
 }
}
.alert-block p + p {
  margin-top: 10px;
}

//pagination
.enable_pagination() when(@enable-pagination = true) {
.pagination > li > span , .pager > li > span {
 border-width: 1px;
}
.pagination > li > a, .pager > li > a {
 border-width: 1px;
 border-color: @pagination-border;

 color: @pagination-color;
 background-color: @pagination-bg;
 margin: 0 -1px 0 0;
 position: relative;
 z-index: auto;

 &:hover, &:focus {
	color: @theme-color-main-text-hover;

	background-color: @pagination-bg-hover;
	border-color: darken(@pagination-border , 6%);
	z-index: 1;
 }
}

.pagination > li.disabled > a, .pager > li.disabled > a {
	&, &:hover {
		background-color: @pagination-bg-disabled;
		border-color: @pagination-border-disabled;
		z-index: auto;
	}
}

.pagination > li.active > a {
	&, &:hover {
		 background-color: @pagination-bg-active !important;
		 border-color: @pagination-border-active !important;
		 color: @pagination-color-active !important;
		 z-index: 2;
	}
}
}
.enable_pagination();


//list group
.list-group-item {
	border-radius: 0 !important;
}

.ui-widget-header, .ui-dialog .ui-dialog-titlebar, .ui-jqdialog .ui-dialog-titlebar, .ui-dialog .ui-jqdialog-titlebar, .ui-jqdialog .ui-jqdialog-titlebar {
    border: 1px solid @theme-color-main-hover !important;
    background: @theme-color-main !important;
    color: #FFF !important;
    font-weight: bold;
}

.ui-datepicker td > a.ui-state-active {
	background-color:@theme-color-main;
	color:#FFF;
	border-color:@theme-color-main-hover;
}

.tr-selected > td {
  background-color:@theme-color-main !important;
  color:#FFF !important;
}

.tr-selected .select-tr > * {
  color:#FFF !important;
}

textarea,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.form-control:focus,
select:focus,
.tags-hover,
.tags-hover:hover,
.ace-file-input .ace-file-container:focus,
.ace-file-input .ace-file-container:hover {
  border-color: @theme-color-main !important;
}
