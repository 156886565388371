@import (reference) "./ace.less";






@table-header-bg:@widget-blue;
@table-sort-active:#555;
@table-sort-hover:#444;

@table-labels-grad-color1:#F8F8F8;
@table-labels-grad-color2:#ECECEC;


//tables
th, td , .table-bordered {
	border-radius:0 !important;
}

.table {
  > thead > tr {
	 color: #707070;
	 font-weight: normal;

	 background: #F7F7F7;
	 #gradient > .vertical(@table-labels-grad-color1 , @table-labels-grad-color2);

	 > th {
		border-color: @table-border-color;
		font-weight: bold;

		.@{icon}:first-child {
			margin-right: 2px;
		}
		&:first-child {
			border-left-color: #F1F1F1;
		}
		&:left-child {
			border-right-color: #F1F1F1;
		}
	 }
  }

  &.table-bordered > thead > tr > th {
	//border-color: @table-border-color;
	vertical-align: middle;

	&:first-child {
		border-left-color: @table-border-color;
	}
  }
}

th , td {
   &.center {
	text-align: center;
   }

   .lbl {
	margin-bottom: 0;
	&:only-child {
		vertical-align: top;
	}
   }
}



.table-header {
	background-color: @table-header-bg;
	color: #FFF;
	font-size: @baseFontSize + 1;
	line-height: 38px;
	padding-left: 12px;
	margin-bottom: 1px;
}
.table-header .close {
   margin-right: 8px;
   margin-top: 0;
   .opacity(0.45);
   &:hover {
	.opacity(0.75);
   }
}


