@import (reference) "../../../ace.less";

.ajaxcomplete {
  position: relative;

  & > .input-group {
    & > .ajaxcomplete--selected {
      display: block;

      border-radius: 0 !important;
      color: @input-color;
      background-color: @input-background;
      border: 1px solid @input-border;

      padding: 5px 4px 6px;
      //line-height: 1.3;
      font-size: @font-size-base;
      font-family: inherit;

      .box-shadow(none) !important;

      .transition-duration(0.1s);

      &:hover {
        border-color: @input-hover-border;
      }
      &:focus {
        //.box-shadow(@input-shadow-focus);
        .box-shadow(none);

        color: @input-color-focus;
        border-color: @input-border-focus;
        background-color: @input-background-focus;
        outline: none;
      }
    }

    & > input {
      width: 100%;
    }
  }

  & > .ajaxcomplete--selection-list {
    position: absolute;
    z-index: 2000;

    & > .ajaxcomplete--selection-state {
      position: absolute;
      right: 0%;
      top: 0%;
    }

    & > .ajaxcomplete--selections {
      background: @input-background;
      border: 1px solid @input-border-focus;
      border-top: none;
      max-height: 200px;
      overflow-y: auto;

      list-style-type: none !important;
      margin: 0 !important;

      & > li {
        padding: 0.25em;

        &.active, &:not(.system):hover {
          background: @option-hover-bg;
        }
      }
    }
  }
}