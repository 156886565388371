@import (reference) "./ace.less";







//some page-content variables
@content-bg: #FFF;
@content-header-border: #e8e8e8;
@content-header-color: @theme-color-main-text;
@content-header-small-color: #8089A0;


html {
  min-height: 100%;
  position: relative;
}

body {
  background-color: @body-background;
  min-height: 100%;
  padding-bottom: 0;

  font-family: 'Open Sans';
  font-size: @baseFontSize;
  color: @text-color;

  line-height: 1.5;
}

a {
	color:@theme-color-main-text;
}

a:hover, a:focus {
	color:@theme-color-main-text-hover;
}

.main-container {
	//this is the white page background, used especially when inside ".container"
	//it will expand all the way down to fill all the page space
	&:before {
		display: block;
		content: "";
		position: absolute;
		z-index: -2;

		width: 100%;
		max-width: inherit;
		bottom: 0;
		top: 0;

		background-color: #fdfdfd;
	}

	&.container {
		&, .rtl & {padding-left: 0; padding-right: 0;}

		&:before {
			@media (min-width: @screen-sm-min) {
				.box-shadow(~"0 0 0 1px rgba(0,0,0,0.1)");
				width: inherit;
			}
		}
	}
}

.main-content {
	margin-left: @sidebar-width;
	min-height: 100%;
	padding: 0;
}

.page-content {
	background-color: @content-bg;
	position: relative;
}

.page-header {
  margin-top:0;
  margin-bottom:0px;
	border-bottom:1px solid @content-header-border;
	padding-bottom: 7px;
  padding-top: 7px;

	h1 {
	  padding: 0;
	  margin: 0 8px;
	  font-size: @font-size-content-header;
	  font-weight: lighter;
	  
	  i {
	  	color: @content-header-color;
	  }

	  small {
		margin: 0 6px;
		font-size: @font-size-content-header-small;
		font-weight: normal;
		color: @content-header-small-color;
	  }//small
	}//h1

	h4 {
	  margin-bottom: 12px;
	}//h4
}

.fake-link {
	color:@theme-color-main-text;

	&:hover {
		color:@theme-color-main-text-hover;
	}
}


