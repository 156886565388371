@import (reference) "./ace.less";






.enable_items() when(@enable-items = true) {

.dialogs {
  padding:9px;
  position:relative;
}
.itemdiv {
  padding-right:3px;
  min-height:66px;
  position:relative;
  
  > .user {
	display:inline-block;
	width:42px;
	position:absolute;
	left:0;
	
	> img {
		border-radius:100%;
		border:2px solid #5293C4;
		max-width:40px;
		position:relative;
	}
  }


  > .body {
	width:auto;	
	margin-left:50px;
	margin-right:12px;

	position:relative;
	
	> .time {
		display: block;
		font-size: @font-size-item-time;
		font-weight: bold;
		color: #666;
 
		position: absolute;
		right: 9px;
		top: 0;

		.@{icon} {
			font-size: @font-size-item-time-icon;
			font-weight: normal;
		}
	}// .body > .time
	
	> .name {
		display: block;
		color: #999;
		 > b { color: #777; }
	}// .body > .name

	> .text {
		display: block;
		position: relative;
		margin-top: 2px;
		 
		padding-bottom: 19px;
		padding-left: 7px;
		
		font-size: @font-size-item-text;

		&:after {
			 display: block;
			 content: "";
			 height: 1px;
			 font-size: 0;
			 overflow: hidden;
			 position: absolute;
			 left: 16px;
			 right: -12px;
			 margin-top: 9px;
			 
			 border-top: 1px solid #E4ECF3;
		}
		
		//> [class*="icon-quote-"]:first-child {
		//quote icon
		> .@{icon}:first-child {
			color: #DCE3ED;
			margin-right: 4px;
		}
	}// .body > .text
  }
  &:last-child > .body > .text {
	border-bottom-width: 0;
	&:after {
		display: none;
	}
  }



 &.dialogdiv {
	padding-bottom: 14px;
	&:before {
		position: absolute;
		display: block;
		content: "";
		 
		top: 0;
		bottom: 0;
		left: 19px;
		
		width: 3px;
		max-width: 3px;

		background-color: #E1E6ED;
		border: 1px solid #D7DBDD;
		border-width: 0 1px;
	}
	&:last-child {
		padding-bottom: 0;
	}
	&:last-child:before {
		display: none;
	}
	
	
	> .user > img {
		border-color: #C9D6E5;
	}
	> .body {
		border: 1px solid #DDE4ED;
		padding: 5px 8px 8px;
		border-left-width: 2px;
		margin-right: 1px;

		&:before{
			content: "";
			display: block;

			position: absolute;
			left: -7px;
			top: 11px;

			width: 8px;
			height: 8px;

			border: 2px solid #DDE4ED;
			border-width: 2px 0 0 2px;
			background-color: #FFF;

			.box-sizing(content-box);

			.rotate(-45deg);
		}
		
		> .time {
			position: static;
			float: right;
		}
		> .text {
			padding-left: 0;
			padding-bottom: 0;
			&:after {display: none;}
		}
	}
	
	.tooltip-inner {
		word-break: break-all;
	}
 }//end of .itemdiv.dialogdiv



 &.memberdiv {
	 width: 175px;
	 @media (min-width: @grid-float-breakpoint) {
		& { max-width: 50%;	}
	 }
	  @media (max-width: @grid-float-breakpoint-max) {
		& {	min-width: 33.333%;	}
	 }

	 padding: 2px;
	 margin: 3px 0;
	 float: left;
	 border-bottom: 1px solid #E8E8E8;
	  
	 > .user > img {
		border-color: #DCE3ED;
	 }

	 > .body {
		> .time {
			position: static;
		}
		> .name {
			line-height: 18px;
			height: 18px;
			margin-bottom: 0;
			> a {
				display: inline-block;
				max-width: 100px;
				max-height: 18px;
				overflow: hidden;
				text-overflow: ellipsis;
				word-break: break-all;
			}
		}
	 }
 }//.itemdiv.memberdiv



 .tools {
	//width:20px;
	position: absolute;
	right: 5px;
	bottom: 10px;
	display: none;
	.btn {
		border-radius: 36px;
		margin: 1px 0;
	}
 }
 .body .tools {
	bottom: 4px;
 }
 &.commentdiv .tools {
	right: 9px;
 }
 &:hover .tools{
	display: inline-block;
 }

}



}
.enable_items();



//task list
.item-list {
  margin:0;
  padding:0;
  list-style:none;

  > li {
	padding:9px;
	background-color:#FFF;
	margin-top:-1px;
	position:relative;
	
	&.selected {
		color:#8090A0;
		background-color:#F4F9FC;
		label , .lbl {
			text-decoration:line-through;
			color:#8090A0;
		}
	}
	> .checkbox {
		display:inline-block;
	}
	> label.inline {
		display:inline-block;
	}
	label {
		font-size: @baseFontSize;
	}
	.percentage {
		font-size: @baseFontSize - 2;
		font-weight: bold;
		color: #777;
	}
	
	&.ui-sortable-helper {
		cursor: move;
	}
 }

}



li[class*="item-"] {
	border:1px solid #E8E8E8;
	border-left-width:3px;
}

.item-list-color(@color) {
  @item-class:~`"item-@{color}"`;
  @item-color:~`"item-list-@{color}-border"`;
  @item-cl:@@item-color;

  li.@{item-class} {
	border-left-color:@item-cl;
  }
}
.item-list-color(~'orange');
.item-list-color(~'orange2');
.item-list-color(~'red');
.item-list-color(~'red2');
.item-list-color(~'green');
.item-list-color(~'green2');
.item-list-color(~'blue');
.item-list-color(~'blue2');
.item-list-color(~'blue3');
.item-list-color(~'pink');
.item-list-color(~'purple');
.item-list-color(~'black');
.item-list-color(~'grey');
.item-list-color(~'brown');
.item-list-color(~'default');






//when dragging
.ui-sortable-placeholder  , .ui-sortable-helper {
  & , & > a {
	cursor:move !important;
  }
}




@media only screen and (max-width: @screen-xs) {
.itemdiv.memberdiv {
  float: none;
  width: auto;
}
}
