@import (reference) "./ace.less";






//error pages
.error-container {
	margin:20px;
	padding:0;
	background:#FFF;
}

@media only screen and (max-width: @screen-xs-max) {
 .error-container {
	margin:12px;
 }
}
@media only screen and (max-width: @screen-tiny-max) {
 .error-container {
	margin:6px;
 }
}

